import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import github from '../../../assets/images/brands/github.png'
import bitbucket from '../../../assets/images/brands/bitbucket.png'
import dribbble from '../../../assets/images/brands/dribbble.png'
import dropbox from '../../../assets/images/brands/dropbox.png'
import mail_chimp from '../../../assets/images/brands/mail_chimp.png'
import slack from '../../../assets/images/brands/slack.png'

//i18n
import { withTranslation } from "react-i18next";

const AppsDropdown = () => {
  const [singlebtn, setSinglebtn] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={singlebtn}
        toggle={() => setSinglebtn(!singlebtn)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <svg width="38" height="38" viewBox="0 0 16 16">
            <path
              fill="currentColor"
              d="M9.5 5a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM9 9.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-2.39-3C6.61 7.328 5.891 8 5 8s-1.61-.672-1.61-1.5S4.109 5 5 5s1.61.672 1.61 1.5M5 8h-.04c-.92 0-1.72.585-1.94 1.42c-.08.295.16.582.485.582h3c.326 0 .565-.286.486-.582C6.768 8.586 5.971 8 5.051 8h-.04z"
            />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M.327 3.64C0 4.282 0 5.12 0 6.8v2.4c0 1.68 0 2.52.327 3.16a3.02 3.02 0 0 0 1.31 1.31c.642.327 1.48.327 3.16.327h6.4c1.68 0 2.52 0 3.16-.327a3 3 0 0 0 1.31-1.31c.327-.642.327-1.48.327-3.16V6.8c0-1.68 0-2.52-.327-3.16a3 3 0 0 0-1.31-1.31c-.642-.327-1.48-.327-3.16-.327h-6.4c-1.68 0-2.52 0-3.16.327a3.02 3.02 0 0 0-1.31 1.31m10.9-.638h-6.4c-.857 0-1.44 0-1.89.038c-.438.035-.663.1-.819.18a2 2 0 0 0-.874.874c-.08.156-.145.38-.18.819c-.037.45-.038 1.03-.038 1.89v2.4c0 .857.001 1.44.038 1.89c.036.438.101.663.18.819c.192.376.498.682.874.874c.156.08.381.145.819.18c.45.036 1.03.037 1.89.037h6.4c.857 0 1.44 0 1.89-.037c.438-.036.663-.101.819-.18c.376-.192.682-.498.874-.874c.08-.156.145-.381.18-.82c.037-.45.038-1.03.038-1.89v-2.4c0-.856-.001-1.44-.038-1.89c-.036-.437-.101-.662-.18-.818a2 2 0 0 0-.874-.874c-.156-.08-.381-.145-.819-.18c-.45-.037-1.03-.038-1.89-.038"
              clipRule="evenodd"
            />
          </svg>

        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end">

          <div className="px-lg-2">
            <SimpleBar >


              <Row className="g-0">
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={github} alt="Github" />
                    <span>GitHub</span>
                  </Link>
                </Col>
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={bitbucket} alt="bitbucket" />
                    <span>Bitbucket</span>
                  </Link>
                </Col>
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={dribbble} alt="dribbble" />
                    <span>Dribbble</span>
                  </Link>
                </Col>
              </Row>
              <Row className="g-0">
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={dropbox} alt="dropbox" />
                    <span>Dropbox</span>
                  </Link>
                </Col>
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={mail_chimp} alt="mail_chimp" />
                    <span>Mail Chimp</span>
                  </Link>
                </Col>
                <Col>
                  <Link className="dropdown-icon-item" to="#">
                    <img src={slack} alt="slack" />
                    <span>Slack</span>
                  </Link>
                </Col>
              </Row>
            </SimpleBar>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(AppsDropdown);