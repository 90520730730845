import PropTypes from "prop-types";
import React, { useEffect } from "react";
import logolight from "../../assets/images/maevisorylogo.png";
import logodark from "../../assets/images/maevisorylogo.png";
import logoSm from "../../assets/images/maevisorylogo.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

//Import config
import { facebook, google } from "../../config";
import { useState } from "react";
import { Modal, Box, Typography, Button, } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AgendaRegistration from "../AgendaRegistration";
import SupervisorSignUp from "../SupervisorSignUp";
import SuperviseeSignUp from "../SuperviseeSignUp";
import SupervisonFormSignUp from "../SignUpForm";
const Login = props => {
  document.title = "Login | Maevisory";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userName: '',
      password: '',
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    }
  });

  const { error } = useSelector(state => ({
    error: state.login.error,
  }));

  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   dispatch(loginUser(values, props.router.navigate));
  // };

  // const signIn = (res, type) => {
  //   if (type === "google" && res) {
  //     const postData = {
  //       name: res.profileObj.name,
  //       email: res.profileObj.email,
  //       token: res.tokenObj.access_token,
  //       idToken: res.tokenId,
  //     };
  //     dispatch(socialLogin(postData, props.router.navigate, type));
  //   } else if (type === "facebook" && res) {
  //     const postData = {
  //       name: res.name,
  //       email: res.email,
  //       token: res.accessToken,
  //       idToken: res.tokenId,
  //     };
  //     dispatch(socialLogin(postData, props.router.navigate, type));
  //   }
  // };

  //handleGoogleLoginResponse
  // const googleResponse = response => {
  //   signIn(response, "google");
  // };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  // const facebookResponse = response => {
  //   signIn(response, "facebook");
  // };
  const [passwordVisible, setPasswordVisible] = useState(false);
  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });
  const navigate = useNavigate();

  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [OpenSignInPageSupervisor, setOpenSignInPageSupervisor] = useState(false);
  const [OpenSignInPageSupervisee, setOpenSignInPageSupervisee] = useState(false);
  const handleCloseSupervisorSign = () => setOpenSignInPageSupervisor(false);
  const handleCloseSuperviseeSign = () => setOpenSignInPageSupervisee(false);

  const handleSignUpOpen = () => setSignUpModalOpen(true);
  const handleSignUpClose = () => setSignUpModalOpen(false);
  const handleNext = () => {
    if (selectedRole === 'Supervisor') {
      // setOpenSignInPageSupervisor(true)
      localStorage.setItem("selectedRole", "Supervisor");
      navigate("/SignUpForm");
    } else if (selectedRole === 'Supervisee') {
      // setOpenSignInPageSupervisee(true)
      localStorage.setItem("selectedRole", "Therapist");
      navigate("/SignUpForm");
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 400, // Fixed height for the modal
    background: "linear-gradient(135deg, #aec3b0, #b0bec5)",
    // border: "1px solid #000",
    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
    p: 4,
    borderRadius: "10px",
    textAlign: "center",
    overflowY: "auto", // Enable vertical scrolling
  };
  const modalStyleSignUp = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 500, // Fixed height for the modal
    backgroundColor: "#aec3b0",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    textAlign: "center",
    overflowY: "auto",
    '&::-webkit-scrollbar': {
      width: 8,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8C7A63',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4,
    },

    '&*': {
      scrollbarWidth: 'thin',
    },
  };

  const buttonStyle = (isActive) => ({
    border: isActive ? '5px solid #8C7A63' : '5px solid #8C7A63',
    borderRadius: '50px',
    padding: '10px 20px',
    color: isActive ? 'white' : '#8C7A63',
    backgroundColor: isActive ? '#8C7A63' : 'transparent',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '15px',
    cursor: 'pointer',
  });

  const arrowStyle = {
    fontSize: '24px', // Increased icon size
    fontWeight: 'bold',
    color: 'inherit',
  };

  const nextButtonStyle = {
    marginTop: '10px',
    border: '3px solid #fff',
    borderRadius: '50px',
    padding: '10px 30px',
    backgroundColor: '#8C7A63',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
  };
  const styles = {
    backgroundImageContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      backgroundImage: 'url("/mavesory.png")',
      backgroundSize: '45%',
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
      zIndex: -3,
    },
    container: {
      minHeight: "100vh",
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "10px",
    },
    innerContainer: {
      background: "linear-gradient(135deg, #aec3b0, #b0bec5)",
      borderRadius: "25px",
      padding: "10px",
      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
      maxWidth: "470px",
      width: "100%",
      marginRight: "5%",
    },
    card: {
      borderRadius: "15px",
      background: "linear-gradient(135deg, #aec3b0, #b0bec5)",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    },
    formWrapper: {
      borderRadius: "25px",
      backgroundColor: "#eeeded",
      padding: "30px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    loginTitle: {
      color: "#8C7A63",
      fontWeight: "bold",
      textAlign: "left",
      fontSize: "28px",
    },
    label: {
      color: "#8C7A63",
    },
    input: {
      backgroundColor: "#aec3b0",
      color: "#333",
      height: "40px",
      border: "1px solid #aec3b0",
      borderRadius: "8px",
      padding: "12px",
    },
    passwordToggle: {
      position: "absolute",
      right: "15px",
      top: "47%",
      transform: "translateY(-50%)",
      cursor: "pointer",
      color: "#8C7A63",
    },
    actionRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "4px",
    },
    rememberMe: {
      fontWeight: "bold",
      fontSize: "14px",
      color: "#333",
    },
    forgotPassword: {
      fontWeight: "bold",
      fontSize: "14px",
      textDecoration: "underline",
      color: "#8C7A63",
      fontStyle: "italic",
      cursor: "pointer",
    },
    submitButton: {
      borderRadius: "20px",
      background: "linear-gradient(135deg, #aec3b0, #b0bec5)",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      color: "white",
      height: "40px",
      border: "none",
      fontSize: "14px",
      fontWeight: "bold",
      transition: "all 0.3s",
      cursor: "pointer",
    },
    signupContainer: {
      display: "flex",
      justifyContent: "space-between",
      fontWeight: "bold",
      fontSize: "14px",
    },
    signupLink: {
      textDecoration: "underline",
      color: "#8C7A63",
      fontStyle: "italic",
      cursor: "pointer",
    },
  };

  return (
    <React.Fragment>
      {/* 
      <div className="account-pages my-5 pt-5"> */}
      <Container maxWidth={false} style={styles.container}>
        <div style={styles.backgroundImageContainer}></div>
        {/* <Col lg={8} md={8} xl={6}>
              <Card style={{ boxShadow: 'none', border: 'none' }}>
                <CardBody style={{ padding: '0', border: 'none' }}>
                  <div style={{ flex: 1 }}>
                    <img
                      src="/mavesory.png"
                      alt="Mavesory"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block', // Prevents default inline image spacing
                        border: 'none',
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col> */}
        <Box style={styles.innerContainer}>
          {/* <Card style={styles.card}> */}
          <Box className="p-4">
            <div style={styles.formWrapper}>
              <h4 style={styles.loginTitle}>LOGIN</h4>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                {error ? <Alert color="danger"><div>{error}</div></Alert> : null}
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label style={styles.label}>User Name</Label>
                      <Input
                        name="userName"
                        style={styles.input}
                        placeholder="Enter user Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.userName || ""}
                        invalid={
                          validation.touched.userName && validation.errors.userName ? true : false
                        }
                      />
                      {validation.touched.userName && validation.errors.userName ? (
                        <FormFeedback type="invalid"><div>{validation.errors.userName}</div></FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label style={styles.label}>Password</Label>
                      <Input
                        style={styles.input}
                        name="password"
                        value={validation.values.password || ""}
                        type={passwordVisible ? 'text' : 'password'}
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={validation.touched.password && validation.errors.password ? true : false}
                      />
                      <div
                        style={styles.passwordToggle}
                        onMouseDown={() => setPasswordVisible(true)}
                        onMouseUp={() => setPasswordVisible(false)}

                      >
                        {passwordVisible ? <VisibilityOff /> : <Visibility />}
                      </div>
                      {validation.touched.password && validation.errors.password ? (
                        <FormFeedback type="invalid"><div> {validation.errors.password} </div></FormFeedback>
                      ) : null}
                    </div>

                    {/* <Row>
                          <Col>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-label form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div>
                          </Col>
                          <Col className="col-7">
                            <div className="text-md-end mt-3 mt-md-0">
                              <Link
                                to="/auth-recoverpw"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock"></i> Forgot your
                                password?
                              </Link>
                            </div>
                          </Col>
                        </Row> */}
                    <div style={styles.actionRow}>
                      <div style={styles.rememberMe}>  <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      />  Remember me</div>
                      <div style={styles.forgotPassword}>Forget Password</div>
                    </div>
                    <div className="d-grid mt-2">
                      <button
                        style={styles.submitButton}
                        // className="btn btn-primary waves-effect waves-light"

                        type="submit"
                      >
                        Log In
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      <div style={styles.signupContainer}>
                        <div>Don't have an account? </div>
                        <div
                          onClick={handleSignUpOpen}
                          style={styles.signupLink}
                        >
                          Sign Up Now
                        </div>
                      </div>

                      {/* <ul className="list-inline">
                        <li className="list-inline-item">
                          <FacebookLogin
                            // appId={facebook.APP_ID}
                            autoLoad={false}
                            // callback={facebookResponse}
                            render={renderProps => (
                              <Link
                                to="#"
                                className="social-list-item bg-primary text-white border-primary"
                                onClick={renderProps.onClick}
                              >
                                <i className="mdi mdi-facebook" />
                              </Link>
                            )}
                          />
                        </li>

                        <li className="list-inline-item">
                          <GoogleLogin
                            // clientId={google.CLIENT_ID}
                            render={renderProps => (
                              <Link
                                to="#"
                                className="social-list-item bg-danger text-white border-danger"
                                onClick={renderProps.onClick}
                              >
                                <i className="mdi mdi-google" />
                              </Link>
                            )}
                            // onSuccess={googleResponse}
                            onFailure={() => { }}
                          />
                        </li>
                      </ul> */}
                    </div>

                  </Col>
                </Row>
              </Form>
            </div>
          </Box>
          {/* </Card> */}
          {/* <div className="mt-5 text-center">
              <p className="text-white-50">
                Don't have an account ?{" "}
                <Link to="/register" className="fw-medium text-primary">
                  {" "}
                  Register{" "}
                </Link>{" "}
              </p>
              <p className="text-white-50">
                © {new Date().getFullYear()} Upzet. Crafted with{" "}
                <i className="mdi mdi-heart text-danger"></i> by Themesdesign
              </p>
            </div> */}
        </Box>
      </Container>
      <Modal open={isSignUpModalOpen} onClose={handleSignUpClose}>
        <Box sx={modalStyle}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
            Choose Your Role
          </Typography>
          <Box
            sx={{
              borderRadius: "25px",
              backgroundColor: "#eeeded",
              padding: "30px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              margin: "auto",
              textAlign: "center",
            }}
          >
            <div
              style={buttonStyle(selectedRole === 'Supervisor')}
              onClick={() => setSelectedRole('Supervisor')}
            >
              <span>Supervisor Sign Up</span>
              <span style={arrowStyle}><ArrowForwardIosIcon style={{ fontWeight: 'bolder' }} /></span>
            </div>

            <div
              style={buttonStyle(selectedRole === 'Supervisee')}
              onClick={() => setSelectedRole('Supervisee')}
            >
              <span>Supervisee Sign Up</span>
              <span style={arrowStyle}><ArrowForwardIosIcon style={{ fontWeight: 'bolder' }} /></span>
            </div>
          </Box>
          <Button style={nextButtonStyle} onClick={handleNext}>
            NEXT <KeyboardDoubleArrowRightIcon style={{
              borderRadius: '50%',
              border: '2px solid white',
              marginLeft: '3px',
              color: '#b3946d',
              backgroundColor: '#fff',
              fontWeight: 'bold'
            }} />
          </Button>
        </Box>
      </Modal>
      <Modal open={OpenSignInPageSupervisor} onClose={handleCloseSupervisorSign} >
        <Box sx={modalStyleSignUp}>

          {/* <SupervisorSignUp setSignUpModalOpen ={setSignUpModalOpen} setOpenSignInPageSupervisor ={setOpenSignInPageSupervisor}></SupervisorSignUp> */}
          {/* <SupervisonFormSignUp handleCloseSupervisorSign={handleCloseSupervisorSign}></SupervisonFormSignUp> */}
        </Box>
      </Modal>
      <Modal open={OpenSignInPageSupervisee} onClose={handleCloseSuperviseeSign} >

        <Box sx={modalStyleSignUp}>
          <SuperviseeSignUp setSignUpModalOpen={setSignUpModalOpen} setOpenSignInPageSupervisee={setOpenSignInPageSupervisee} selectedRole={selectedRole}></SuperviseeSignUp>
        </Box>
      </Modal>

      {/* </div> */}
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
