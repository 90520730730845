// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography, Modal, Paper, Checkbox, TextField } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import { ToastContainer, toast } from 'react-toastify';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import TherapistRegistration from '../TherapistRegistration'
import ReactSelect from "react-select";
import moment from 'moment';
const Therapist = () => {
  document.title = "Therapist | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#377562',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }

  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();
  const [Therapistid, setTherapistid] = useState('')
  const [Therapist, setTherapist] = React.useState(false);
  const handleTherapist = () => {
    setTherapist(true)
  };
  const handlecloseTherapist = () => {
    setTherapistid('')
    // setLomnRequestid('')
    //  setPatientNamee('')
    setTherapist(false)
  };
  const columns = [
    {
      field: 'userName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button style={{ justifyContent: 'flex-start' }}
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            // openUserRegistration(event,cellValues.row.id )
            setTherapistid(cellValues.row.id)
          }}
        >{`${cellValues.row.userName}`}</Button>
      )
    },

    {
      field: 'email',
      headerClassName: 'super-app-theme--header',
      headerName: 'Email Address',
      flex: 0.5
    },
    // {
    //   field: 'fullName',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Full Name',
    //   flex: 0.3
    // },
    {
      field: 'userType',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Type',
      flex: 0.3
    },
    {
      field: 'dateJoined',
      headerClassName: 'super-app-theme--header',
      headerName: 'Sign Up Date',
      flex: 0.3,
      valueGetter: (params) => moment(params.row.dateJoined).format('MM-DD-YYYY') 
    },
    {
      field: 'subscriptionStatus',
      headerClassName: 'super-app-theme--header',
      headerName: 'Subscription Status',
      flex: 0.3
    },
    {
      field: 'isActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {

                      isActive: true,
                      id: cell.row.id,
                      FullName:cell.row.userName,
                      userName:cell.row.userName,
                      fullName:cell.row.fullName,

                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/Users/userRegistration`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
  ]
  useEffect(() => {
    if (Therapistid > 0) {
      handleTherapist()
    }
  }, [Therapistid]);
  const [rows, setRows] = useState(null)
  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => { })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])

  const openUserRegistration = (event, id = 0) => {
    navigate('/TherapistRegistration', { state: { uid: id } })
  }
  const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    // overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
  }
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const handleSubscribeChange  = () => {
    if(isSubscribe == false){
      setIsSubscribe(true);
    }else if (isSubscribe == true){
      setIsSubscribe(false)
    }
  };
  useEffect(() => {
      onPatientHandle();
  }, [isSubscribe ,showAll]);
  const handleShowAllChange = (event) => {
    onPatientHandle()
    if(showAll == false){
      setShowAll(true);
    }else if (showAll == true){
      setShowAll(false)
    }
  };
  const [User, setuser] = useState(false);

  const onPatientHandle = async (value) => {
    let postData = {
      isSubscribe: isSubscribe,
      showAll: showAll,
      userType: 'Therapist',
      searchTerm: value,
    };
    axios.get(`${process.env.REACT_APP_API_URL}/Users/getUsers`, {
      params: postData,
      headers: headers
    })
      .then((response) => {
        setRows(response)
        console.log('Response:', response);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  };

  return (
    <>
      <Modal
        open={Therapist}
        onClose={handlecloseTherapist}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={true} title={'Title'}
      >
        <Box sx={style}>
          <Headroom >
            <Grid
              container
              style={{ backgroundColor: '#356F60', position: 'sticky' }}
            >
              <Grid item xs={12} sm={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'space-between',
                    height: '2rem'
                  }}
                >
                  <DialogTitle
                    style={{
                      flex: 1,
                      // textAlign: 'left',
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        // textAlign: 'left',
                        marginTop: '-10px',
                        color: '#fff',
                        textDecoration: 'underline',
                        fontSize: '1rem'

                      }}
                    >
                      Therapist Registration
                    </h1>
                  </DialogTitle>
                  <CancelOutlinedIcon
                    onClick={handlecloseTherapist}
                    fontSize="large"
                    color="red"
                    style={{
                      color: 'red',
                      paddingRight: '5px',
                      cursor: 'pointer',
                      float: 'right'
                      // marginLeft: 'auto',
                      // marginRight: '1.5rem'
                    }}
                  ></CancelOutlinedIcon>
                </div>
              </Grid>
            </Grid>
          </Headroom>




          <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              overflow: 'auto',
              width: '600px',
              /* Other styles */
              scrollbarWidth: 'thin', // For Firefox

              scrollbarColor: '#356F60 transparent', // thumb color and track color

              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#356F60', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
              // zIndex: "10",


              height: '80vh'
            }} >
              <TherapistRegistration uid={Therapistid} handlecloseTherapist={handlecloseTherapist} setRefresh={setRefresh} refresh={refresh}></TherapistRegistration>



            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"

            />
          </Grid>


        </Box>
      </Modal>
      <div className="page-content">
        <Container >
          <Grid container spacing={1}>

            <Grid item xs={12}>
              <Paper
                elevation={4}
                style={{
                  padding: '20px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '8px',
                  marginBottom: '16px',
                  width: '100%',
                  margin: '10px auto',
                  border: '2px solid #326C1D',
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={1.3}>
                    <Typography
                      style={{
                        color: '#333',
                        fontSize: '18px',
                        marginTop: '6px',
                        fontWeight: 'bold',
                        width: '90px',
                      }}
                    >
                      User:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      placeholder="Enter Therapist"
                      onKeyUp={(ev) => {
                        onPatientHandle(ev.target.value);
                      }}
                    />


                  </Grid>
                  <Grid item xs={2} mt={1}>

                    <Checkbox
                      style={{ marginTop: '-12px' }}
                      // value={isSubscribe == true}
                      // onChange={(e) => setIsSubscribe(true)}
                      value={isSubscribe == true}
                      onChange={handleSubscribeChange}
                    />
                    <Typography style={{ marginTop: '-33px', marginLeft: '40px' }}>Subscribe </Typography>
                  </Grid>
                  <Grid item xs={2} mt={1}>
                    <Checkbox
                      style={{ marginTop: '-12px' }}
                      value={showAll}
                      onChange={handleShowAllChange}
                    />
                    <Typography style={{ marginTop: '-33px', marginLeft: '40px' }}>Show All </Typography>
                  </Grid>
                  <Grid item xs={2} >
                    <Button sx={{ mb: 1 }} className="Button" fullWidth onClick={handleTherapist} variant='contained' style={{ fontSize: '14px', fontWeight: 'bold' }}>
                      <AddOutlinedIcon
                        fontSize='small'
                        style={{
                          color: 'white',
                          paddingRight: '4px',
                          paddingBottom: '2px'
                        }}
                      ></AddOutlinedIcon>
                      Add New
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} xl={12}>
              <Box mb={0} style={{ marginTop: 0 }}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rows === null || rows.length === 0 ? ("No Record Found") : (
                      <DataGrid
                        rows={rows}
                        classes={gridClasses}
                        rowHeight={25}
                        columns={columns}
                        getRowSpacing={getRowSpacing}
                        //checkboxSelection
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container >
      </div >
    </>


  )
}

export default Therapist
