// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography, TextField, NativeSelect, Modal } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Paper from '@mui/material/Paper'
import ReactSelect from "react-select";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Controller, Form, useForm } from "react-hook-form";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Card from '@mui/material/Card';
import { ToastContainer, toast } from 'react-toastify';
import TrackHoursRegistration from '../TrackHoursRegistration'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import moment from 'moment'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const TrackHours = () => {
  document.title = "TrackHours | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const Userid = localStorage.getItem('userid')


  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }

  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    // defaultValues: defValues
  })
  const [refresh, setRefresh] = useState(false)
  function applyRefresh() {
    setRefresh(!refresh)
  }
  const navigate = useNavigate();
  const [TrackHoursid, setTrackHoursid] = useState('')
  const [TrackHours, setTrackHours] = React.useState(false);
  const handleTrackHours = () => {
    setTrackHours(true)
  };
  const handlecloseTrackHours = () => {
    setTrackHoursid('')
    // setLomnRequestid('')
    //  setPatientNamee('')
    setTrackHours(false)
  };
  const columns = [
    {
      field: 'trackType',
      headerClassName: 'super-app-theme--header',
      headerName: 'Track Type',
      cellClassName: 'gridCell',
      flex: 0.19,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            // openUserRegistration(event,cellValues.row.id )
            setTrackHoursid(cellValues.row.id)
          }}
        >{`${cellValues.row.trackType}`}</Button>
      )
    },

    {
      field: 'trackDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Date',
      flex: 0.18
    },
    {
      field: 'therpistName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Therapist Name',
      flex: 0.26
    },
    {
      field: 'levelOne',
      headerClassName: 'super-app-theme--header',
      headerName: 'Level One',
      flex: 0.26
    },
    {
      field: 'levelTwo',
      headerClassName: 'super-app-theme--header',
      headerName: 'Level Two',
      flex: 0.26
    },
    {
      field: 'approveDateTime',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approved Date',
      flex: 0.35,
      renderCell: (params) => {
        return params.value ? moment(params.value).format('MM/DD/YYYY HH:mm') : '';
      },
    },
    {
      field: 'approvedBy',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approved By',
      flex: 0.23
    },
    {
      field: 'approve',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approve',
      flex: 0.16,
      renderCell: cellValues => {
        return cellValues.row.approve ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : (
          <CloseIcon style={{ color: 'red' }} />
        )
      }
    },
    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.16,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {

                      inActive: true,
                      id: cell.row.id
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/TrackHours/addTrackHours`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }

  ]
  useEffect(() => {
    if (TrackHoursid > 0) {
      handleTrackHours()
    }


  }, [TrackHoursid]);
  const [rows, setRows] = useState([])
  const [rowsTrack, setRowsTrack] = useState(null)
  const [selectedValue, setSelectedValue] = useState('')
  const [seletedTherpist, setseletedTherpist] = useState(0);
  const [TherapistName, setTherapistName] = useState('');
  const [TherapistFilter, setTherapistFilter] = useState(false);
  console.log('The Filter Data', rows)
  const handleChangetype = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    applyRefresh(newValue)
    console.log("Selected value my kya ha : ", newValue)
  }
  const onFilterSearchtype = event => {
    if (userType === 'Supervisor' || userType === "Admin") {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/TrackHours/getTrackHours?requestString=${selectedValue}&Therpistid=${seletedTherpist ? seletedTherpist : ''}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
        })
        .catch(error => { })
    } else {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/TrackHours/getTrackHours?requestString=${selectedValue}&Therpistid=${Userid}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)
        })
        .catch(error => { })

    }

  }
  const [Data, setData] = useState([])

  useEffect(() => {
    if (userType === 'Supervisor' || userType === "Admin") {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/TrackHours/getTrackHours?requestString=${selectedValue}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)
        })
        .catch(error => { })

    }
  }, [refresh, selectedValue])
  useEffect(() => {
    if (userType == 'Therapist') {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/TrackHours/getTrackHours?requestString=${selectedValue}&Therpistid=${Userid}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)
        })
        .catch(error => { })

    }
  }, [refresh, selectedValue, Userid])

  const openUserRegistration = (event, id = 0) => {
    navigate('/TrackHoursRegistration', { state: { uid: id } })
  }
  const [value, setvalue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };
  const [fromDate, setfromDate] = React.useState('')
  const [toDate, settoDate] = useState('')
  const onFilterSearch = event => {
    if (userType === 'Supervisor' || userType === "Admin") {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/TrackHours/getSummaryTrackHours?fromDate=${fromDate}&toDate=${toDate}&userId=${seletedTherpist ? seletedTherpist : ''}`,
          { headers }
        )
        .then(response => {
          console.log('response.data: ', response)
          setRowsTrack(response)
          setLoading(false)
        })
        .catch(error => {
          console.error('There was an error!', error)
        })
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/TrackHours/getSummaryTrackHours?fromDate=${fromDate}&toDate=${toDate}&userId=${Userid}`,
          { headers }
        )
        .then(response => {
          console.log('response.data: ', response)
          setRowsTrack(response)
          setLoading(false)
        })
        .catch(error => {
          console.error('There was an error!', error)
        })

    }

  }
  const style = {
    position: 'absolute',
    top: '54%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.5)',
    padding: '15px',
    borderRadius: '8px',
    maxHeight: '89vh',
    // overflow: 'auto',

  };
  const formatTime = (timeInMinutes) => {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    return `${hours} hr ${minutes > 0 ? `${minutes} mnts` : ''}`;
  };
  const [searchClicked, setSearchClicked] = useState(false);

  const handleDownload = async () => {
    const doc = new jsPDF();

    const margin = 10;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    doc.rect(margin, margin, pageWidth - margin * 2, pageHeight - margin * 2);

    const title = 'Time Tracking Report';
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 102, 204);

    const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const titleX = (pageWidth - titleWidth) / 2;

    doc.text(title, titleX, 20);
    let yPosition = 30;

    if (TherapistFilter === true) {
      const reportText = `${TherapistName}'s Report`;
      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      const reportTextWidth = doc.getStringUnitWidth(reportText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const reportTextX = (pageWidth - reportTextWidth) / 2;
      doc.text(reportText, reportTextX, yPosition);
      yPosition += 10;
    } else {
      yPosition += 5;
    }

    const columns = [
      { header: 'Track Date', dataKey: 'trackDate' },
      { header: 'Therapist Name', dataKey: 'therapistName' },
      { header: 'Track Time Start', dataKey: 'trackTimeStart' },
      { header: 'Track Time End', dataKey: 'trackTimeEnd' },
      { header: 'Track Type', dataKey: 'trackType' },
      { header: 'Location Site', dataKey: 'locationSite' },
    ];

    const rows = Data.map(item => ({
      trackDate: item.trackDate,
      therpistName: item.therpistName,
      trackTimeStart: item.trackTimeStart,
      trackTimeEnd: item.trackTimeEnd,
      trackType: item.trackType,
      locationSite: item.locationSite,
    }));

    // const directCount = Data.filter(item => item.trackType === 'direct').length;
    // const indirectCount = Data.filter(item => item.trackType === 'Indirect').length;

    doc.autoTable({
      head: [columns.map(col => col.header)],
      body: rows.map(item => Object.values(item)),
      startY: yPosition, // Adjusted for potential Therapist title
      didDrawPage: (data) => {
        const finalY = data.cursor.y + 10;

        doc.setFontSize(10);
        doc.text(`Number of Directs Hours: ${directHours} hours and ${directMinutes} minutes`, 14, finalY);
        doc.text(`Number of Indirects Hours: ${indirectHours} hours and ${indirectMinutes} minutes`, 14, finalY + 10);
      }
    });
    const fileName = `TimeTracking_Report_${moment().format('DD_MMM_YY')}.pdf`;

    doc.save(fileName);
  };





  const [TherapistList, setTherapistList] = useState([]);

  const userType = localStorage.getItem('User_Type');

  const Direct_Hours_Have = localStorage.getItem('Direct_Hours_Have');
  console.log('Direct_Hours_Have', Direct_Hours_Have)
  const Direct_Hours_Required = localStorage.getItem('Direct_Hours_Required');
  const INdirect_Hours_Have = localStorage.getItem('INdirect_Hours_Have');
  const Inirect_Hours_Required = localStorage.getItem('Inirect_Hours_Required');

  useEffect(() => {
    if (userType == 'Admin') {
      axios
        .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
          headers
        })
        .then(response => {
          var prac = [];
          response.forEach(field => {
            prac.push({
              label: field.userName,
              value: field.id
            });
          });
          setTherapistList(prac);
        })
        .catch(error => { });
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapistList?supervisorId=${Userid}`, {
          headers
        })
        .then(response => {
          var prac = [];
          response.forEach(field => {
            prac.push({
              label: field.userName,
              value: field.id
            });
          });
          setTherapistList(prac);
        })
        .catch(error => { });
    }
  }, []);
  const onSeacrhButtonClick = event => {
    setLoading(true)
    setTherapistFilter(true)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/TrackHours/getTrackHours?requestString=${selectedValue}&Therpistid=${seletedTherpist ? seletedTherpist : ''}`,
        { headers }
      )
      .then(response => {
        console.log('response.data: ', response)
        setData(response)
        setRows(response)
        setLoading(false)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })


  }
  const timeStringToMinutes = (timeStr) => {
    if (!timeStr) return 0; // Return 0 if timeStr is null or undefined
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return hours * 60 + minutes + seconds / 60;
  };

  const convertMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return { hours, minutes: remainingMinutes };
  };
  const rowsArray = Array.isArray(rows) ? rows : [];
  const directTotalMinutes = rowsArray
    .filter(row => row.trackType === "direct" && row.trackTimeStart && row.trackTimeEnd)
    .reduce((sum, row) =>
      sum + (timeStringToMinutes(row.trackTimeEnd) - timeStringToMinutes(row.trackTimeStart)),
      0
    );

  const indirectTotalMinutes = rowsArray
    .filter(row => row.trackType === "Indirect" && row.trackTimeStart && row.trackTimeEnd)
    .reduce((sum, row) =>
      sum + (timeStringToMinutes(row.trackTimeEnd) - timeStringToMinutes(row.trackTimeStart)),
      0
    );


  const { hours: directHours, minutes: directMinutes } = convertMinutesToHours(directTotalMinutes);
  const { hours: indirectHours, minutes: indirectMinutes } = convertMinutesToHours(indirectTotalMinutes);


  const directHoursToMinutes = Direct_Hours_Have * 60; // Convert Direct_Hours_Have to minutes
  const combinedMinutes = directHoursToMinutes + (directHours * 60) + directMinutes;

  // Convert back to hours and minutes
  const totalHours = Math.floor(combinedMinutes / 60);
  const totalMinutes = combinedMinutes % 60;

  // Store result in a single constant
  const totalTime = `${totalHours} hours and ${totalMinutes} minutes`;

  console.log('totalTime', totalTime);

  return (
    <>
      <Modal
        open={TrackHours}
        onClose={handlecloseTrackHours}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={true} title={'Title'}
      >
        <Box sx={style}>
          <Headroom >
            <Grid
              container
              style={{ backgroundColor: '#356F60', position: 'sticky' }}
            >
              <Grid item xs={12} sm={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'space-between',
                    height: '2rem'
                  }}
                >
                  <DialogTitle
                    style={{
                      flex: 1,
                      // textAlign: 'left',
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        // textAlign: 'left',
                        marginTop: '-10px',
                        color: '#fff',
                        textDecoration: 'underline',
                        fontSize: '1rem'

                      }}
                    >
                      Track Hours Registration

                    </h1>
                  </DialogTitle>
                  <CancelOutlinedIcon
                    onClick={handlecloseTrackHours}
                    fontSize="large"
                    color="red"
                    style={{
                      color: 'red',
                      paddingRight: '5px',
                      cursor: 'pointer',
                      float: 'right'
                      // marginLeft: 'auto',
                      // marginRight: '1.5rem'
                    }}
                  ></CancelOutlinedIcon>
                </div>
              </Grid>
            </Grid>
          </Headroom>




          <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              padding: '5px',
              maxHeight: '57vh',
              overflowY: 'auto',
              marginTop: '4px',
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#3676c4 transparent', // thumb color and track color
              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#3676c4', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
            }} >
              <TrackHoursRegistration uid={TrackHoursid} handleClose={handlecloseTrackHours} selectedValue={selectedValue} ></TrackHoursRegistration>


            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"

            />
          </Grid>


        </Box>
      </Modal>
      <div className="page-content">
        <Container >
          <Grid item xs={12}>
            <Paper
              elevation={4}
              style={{
                padding: '20px',
                backgroundColor: '#f5f5f5',
                borderRadius: '8px',
                marginBottom: '16px',
                width: '100%',
                margin: '10px auto',
                border: '2px solid #326C1D',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      color: 'black',
                      fontSize: '18px',
                      marginTop: '6px',
                      fontWeight: 'bold', // Make bold
                    }}
                  >
                    Type:
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <NativeSelect
                    onChange={handleChangetype}
                    value={selectedValue}
                    style={{
                      width: '100%',
                      height: '40px',
                      padding: '5px 10px',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: '400',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      outline: 'none',
                      appearance: 'none',
                      backgroundColor: 'white',
                    }}
                  >
                    <option value='all'>All</option>
                    <option value='pending'>Pending</option>
                    <option value='approved'>Approved</option>
                  </NativeSelect>
                </Grid>

                {userType === 'Supervisor' || userType === 'Admin' ? (
                  <>
                    <Grid item xs={1.3}>
                      <Typography
                        style={{
                          color: '#333',
                          fontSize: '18px',
                          marginTop: '6px',
                          fontWeight: 'bold',
                          width: '90px',
                        }}
                      >
                        Therapist:
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <ReactSelect
                        options={TherapistList}
                        value={TherapistList.find(
                          (option) => option.value === getValues('therapistsId')
                        )}
                        onChange={(selectedOption) => {
                          const therapistId = selectedOption ? selectedOption.value : 0;
                          setValue('therapistsId', therapistId);
                          setseletedTherpist(therapistId);
                          setTherapistName(selectedOption.label)

                        }}
                        styles={{
                          container: (base) => ({
                            ...base,
                            width: '100%',
                          }),
                          control: (base) => ({
                            ...base,
                            height: '40px',
                            padding: '0px 5px',
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: '400',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            backgroundColor: 'white',
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            padding: '0px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: '#aaa',
                          }),
                        }}
                        placeholder="Select Therapist"
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                      <Button
                        className="Button"
                        onClick={onSeacrhButtonClick}
                        fullWidth
                        // disabled={onFilterSearch}
                        variant='contained' style={{
                          fontSize: '14px', fontWeight: 'bold',
                        }}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={1}></Grid>
                )}

                <Grid container item xs={userType === 'Therapist' ? 6 : 12} justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      className="Button"
                      onClick={handleDownload}
                      fullWidth
                      disabled={!Data || Data.length === 0}
                      variant='contained' style={{
                        fontSize: '14px', fontWeight: 'bold',
                      }}
                      type="submit"
                    >
                      Download
                      <DownloadForOfflineIcon />
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button className="Button" onClick={onFilterSearchtype} variant='contained' style={{
                      fontSize: '14px', fontWeight: 'bold',
                    }}>
                      <CachedOutlinedIcon
                        fontSize='small'
                        style={{
                          color: 'white',
                          paddingRight: '4px',
                          paddingBottom: '2px',

                        }}
                      ></CachedOutlinedIcon>
                      Refresh
                    </Button>
                  </Grid>

                  <Grid item>
                    {userType === 'Therapist' ? (
                      <Grid item >
                        <Button className="Button" onClick={handleTrackHours} variant='contained' style={{
                          fontSize: '14px', fontWeight: 'bold',
                        }}>
                          <AddOutlinedIcon
                            fontSize='small'
                            style={{
                              color: 'white',
                              paddingRight: '4px',
                              paddingBottom: '2px',

                            }}
                          ></AddOutlinedIcon>
                          Add New
                        </Button>
                      </Grid>
                    ) :
                      null
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid container spacing={1}>

            <Grid item xs={12} sm={12} xl={12}>

              <Box mb={0} style={{ marginTop: 0 }}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rows === null || rows.length === 0 ? ("No Record Found") : (
                      <DataGrid
                        rows={rows}
                        classes={gridClasses}
                        rowHeight={25}
                        columns={columns}
                        getRowSpacing={getRowSpacing}
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}
              </Box>
            </Grid>
            {isLoading ? (
              ''
            ) : (
              ""
              // <Grid item xs={12} sm={12} xl={12}>

              // <Paper
              //   elevation={4}
              //   style={{
              //     padding: '20px',
              //     backgroundColor: '#f5f5f5',
              //     borderRadius: '8px',
              //     marginBottom: '16px',
              //     width: '100%',
              //     margin: '10px auto',
              //     border: '2px solid #326C1D',
              //   }}
              // >
              //     <Typography
              //       variant="body1"
              //       sx={{
              //         backgroundColor: '#e0f7fa',
              //         padding: '10px',
              //         borderRadius: '8px',
              //         boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              //         fontSize: '1rem',
              //         marginBottom: '10px',
              //         textAlign: 'center'
              //       }}
              //     >
              //       Total time for Direct Hours: <span style={{ fontWeight: 'bold' }}>{directHours + Direct_Hours_Have} hours and {directMinutes} minutes</span>
              //     </Typography>

              //     <Typography
              //       variant="body1"
              //       sx={{
              //         backgroundColor: '#e0f7fa',
              //         padding: '10px',
              //         borderRadius: '8px',
              //         boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              //         fontSize: '1rem',
              //         textAlign: 'center'
              //       }}
              //     >
              //   Total time for Indirect Hours: <span style={{ fontWeight: 'bold' }}>{indirectHours + INdirect_Hours_Have} hours and {indirectMinutes} minutes</span>
              //     </Typography>
              // </Paper>
              //   </Grid>
            )}

            <Grid item xs={12} mb={2}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container >

                    <Grid xs={8}>
                      <h4>Track Hours</h4>
                    </Grid>
                    <Grid xs={4}>

                      <Typography><b>Total Hours =</b> {totalTime}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>


                    {userType === 'Therapist' ? (
                      <Grid item xs={6} style={{ display: 'flex' }} >

                        <Paper
                          elevation={4}
                          style={{
                            padding: '20px',
                            backgroundColor: '#f5f5f5',
                            borderRadius: '8px',
                            marginBottom: '16px',
                            width: '100%',
                            margin: '10px auto',
                            border: '2px solid #326C1D',
                          }}
                        >
                          {/* <Typography
                    variant="body1"
                    sx={{
                      backgroundColor: '#e0f7fa',
                      padding: '10px',
                      borderRadius: '8px',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      fontSize: '1rem',
                      marginBottom: '10px',
                      textAlign: 'center'
                    }}
                  >
                    <b>Previous Track Hours</b>
                  </Typography> */}
                          <Typography
                            variant="body1"
                            sx={{
                              backgroundColor: '#e0f7fa',
                              padding: '10px',
                              borderRadius: '8px',
                              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                              fontSize: '1rem',
                              marginBottom: '10px',
                              textAlign: 'center'
                            }}
                          >
                            Direct hours required for License Type : <span style={{ fontWeight: 'bold' }}>{Direct_Hours_Required} hours</span>
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              backgroundColor: '#e0f7fa',
                              padding: '10px',
                              borderRadius: '8px',
                              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                              fontSize: '1rem',
                              marginBottom: '10px',
                              textAlign: 'center'
                            }}
                          >
                            Previous Track Hours: <span style={{ fontWeight: 'bold' }}>{Direct_Hours_Have} hours</span>
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              backgroundColor: '#e0f7fa',
                              padding: '10px',
                              borderRadius: '8px',
                              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                              fontSize: '1rem',
                              marginBottom: '10px',
                              textAlign: 'center'
                            }}
                          >
                            Indirect hours required for License  Type : <span style={{ fontWeight: 'bold' }}>{Inirect_Hours_Required} hours</span>
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              backgroundColor: '#e0f7fa',
                              padding: '10px',
                              borderRadius: '8px',
                              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                              fontSize: '1rem',
                              textAlign: 'center'
                            }}
                          >
                            Total time for Indirect Hours: <span style={{ fontWeight: 'bold' }}>{INdirect_Hours_Have} hours</span>
                          </Typography>
                        </Paper>
                      </Grid>
                    ) : ''}
                    <Grid item xs={userType === 'Therapist' ? 6 : 12} style={{ display: 'flex' }}>

                      <Paper
                        elevation={4}
                        style={{
                          padding: '20px',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '8px',
                          marginBottom: '16px',
                          width: '100%',
                          margin: '10px auto',
                          border: '2px solid #326C1D',
                        }}
                      >
                        {/* <Typography
                  variant="body1"
                  sx={{
                    backgroundColor: '#e0f7fa',
                    padding: '10px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    fontSize: '1rem',
                    marginBottom: '10px',
                    textAlign: 'center'
                  }}
                >
                  <b>On-going Track Hours</b>
                </Typography> */}
                        <Typography
                          variant="body1"
                          sx={{
                            backgroundColor: '#e0f7fa',
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            fontSize: '1rem',
                            marginBottom: '10px',
                            textAlign: 'center'
                          }}
                        >
                          On-going Track Hours: <span style={{ fontWeight: 'bold' }}>{directHours} hours and {directMinutes} minutes</span>
                        </Typography>

                        <Typography
                          variant="body1"
                          sx={{
                            backgroundColor: '#e0f7fa',
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            fontSize: '1rem',
                            textAlign: 'center'
                          }}
                        >
                          Total time for Indirect Hours: <span style={{ fontWeight: 'bold' }}>{indirectHours} hours and {indirectMinutes} minutes</span>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* <Grid item xs={12} sm={12} xl={12}>
               <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="lab API tabs example"
                  >
                    <Tab value={0} label="All" />
                    <Tab value={1} label="Summary" />


                  </TabList>
                </Box>
                <TabPanel value={0}>
                  <Box mb={0} style={{ marginTop: 0 }}>
                    {isLoading ? (
                      <div style={{ margin: '5rem', textAlign: 'center' }}>
                        <CircularProgress />
                      </div>
                    ) : (
                      <div mb={0} style={{ height: 400, width: '100%' }}>
                        {rows === null || rows.length === 0 ? ("No Record Found") : (
                          <DataGrid
                            rows={rows}
                            classes={gridClasses}
                            rowHeight={25}
                            columns={columns}
                            getRowSpacing={getRowSpacing}
                            //checkboxSelection
                            fullWidth
                            sx={gridRowStyle}
                            pagination
                            autoPageSize
                          />
                        )}
                      </div>
                    )}
                  </Box>
                </TabPanel>
                <TabPanel value={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={1.5} sm={1.5} xl={1.5}>
                      <Typography style={{ marginTop: '8px', color: 'black', }}> From Date </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} xl={3}>
                      <Controller
                        name='fromDate'
                        {...register('fromDate')}
                        defaultValue={fromDate}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            // isClearable
                            size='small'
                            type='date'
                            // disabled={isChecked == true ? (true) : (false)}
                            {...field}
                            fullWidth
                            value={fromDate}
                            // error={errors.fromDate}
                            onChange={e => {
                              setValue('fromDate', e.target.value)
                              setfromDate(e.target.value)

                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1} xl={1}> </Grid>
                    <Grid item xs={1} sm={1} xl={1}>
                      <Typography style={{ marginTop: '8px', color: 'black', }}> To Date </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} xl={3}>
                      <Controller
                        name='toDate'
                        defaultValue={toDate}

                        {...register('toDate')}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            // isClearable
                            size='small'
                            type='date'
                            // disabled={isChecked == true ? (true) : (false)}
                            {...field}
                            fullWidth
                            value={toDate}
                            // error={errors.fromDate}
                            onChange={e => {
                              setValue('toDate', e.target.value)
                              settoDate(e.target.value)

                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.5} sm={2.5} xl={2.5}>
                      <Button sx={{ mb: 1 }} className="Button"
                        //  onClick={onFilterSearch}
                        // onClick={() => { setSearchClicked(true); onFilterSearch(); }} 
                        disabled={!fromDate || !toDate} // disable the button if either value is falsy
                        onClick={() => {
                          if (!fromDate || !toDate) {
                            // display an error message or do something else to indicate that the fields are required
                            alert("Please enter both a from date and a to date.");
                            return;
                          }
                          setSearchClicked(true);
                          onFilterSearch();
                        }}
                        variant='contained' style={{ fontSize: '14px', fontWeight: 'bold', float: 'right' }}>
                        <SearchOutlinedIcon
                          fontSize='small'
                          style={{
                            color: 'white',
                            paddingRight: '4px',
                            paddingBottom: '2px'
                          }}
                        ></SearchOutlinedIcon>
                        Search
                      </Button>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} xl={12}>
                {rowsTrack != null && rowsTrack.length > 0
                  ? rowsTrack.map((row, index) => {
                    return(
                      <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '5px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.9rem',
                        marginBottom: '1rem',
                        marginLeft: '0 .2rem'
                      }}
                    >
                     <Grid container spacing={1}>
                     <Grid item xs={6} sm={6} xl={6}>
                      <Typography style={{ fontSize: '14px' }}>
                       <b>Activity Type:</b>  {row.activityType}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                      <Typography style={{ fontSize: '14px',color:'black' }}>
                      <b>Track Hours :</b> {formatTime(row.trackHours)}
                        </Typography>
                      </Grid>
                     </Grid>
                    </Paper>
                    )
                   
                  })
                  : 'No Record Found'}
                </Grid> 
                    <Grid item xs={12} sm={12} xl={12}>
                      {searchClicked && rowsTrack != null && rowsTrack.length === 0
                        ? 'No Record Found'
                        : rowsTrack != null && rowsTrack.length > 0
                          ? rowsTrack.map((row, index) => {
                            return (
                              <Paper
                                variant='outlined'
                                square
                                style={{
                                  backgroundColor: '#F0F6F8',
                                  borderRadius: '15px',
                                  marginTop: '5px',
                                  borderColor: '#377562',
                                  maxWidth: '100%',
                                  padding: '.9rem',
                                  marginBottom: '1rem',
                                  marginLeft: '0 .2rem'
                                }}
                              >
                                <Grid container spacing={1}>
                                  <Grid item xs={6} sm={6} xl={6}>
                                    <Typography style={{ fontSize: '14px' }}>
                                      <b>Activity Type:</b> {row.activityType}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} sm={6} xl={6}>
                                    <Typography style={{ fontSize: '14px', color: 'black' }}>
                                      <b>Track Hours:</b> {formatTime(row.trackHours)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Paper>
                            );
                          })
                          : ''}
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext> 


            </Grid>*/}


          </Grid>

        </Container>
      </div>
    </>


  )
}

export default TrackHours
