import React, { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    Typography,
    Button,
    Container,
    Card,
    CardContent,
    CircularProgress,
    LinearProgress,
    IconButton,
    Grid,
    InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import { useLocation, Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
const ReniewSubscrition = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const useremail = location.state?.useremail;
    const userType = location.state?.usertype;
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/Users/GetAllPackage`)
            .then((response) => {
                setPackages(response);
                // Store the fetched data
                setLoading(false); // Set loading to false when the API call completes
            })
            .catch((error) => {
                console.error('Error fetching packages:', error);
                setLoading(false); // Set loading to false in case of an error
            });
    }, []);
    const [error, setError] = useState('');
    const handleCardClick = (duration, stripeProductId) => {
        setSelectedPackage({ duration, stripeProductId });
        setError('')
    };
    const [pakid, setpakid] = useState()
    const [step, setStep] = React.useState(1);
    const handleNext = (event) => {
        event.preventDefault();
        if (step === 1) {
            if (!selectedPackage) {
                setError('Please select a package before proceeding.');
                return;
            }
        }
        // if (step === 2) {
        //     if (!createStripeClicked && !linkStripeClicked) {
        //         setStripeError(true);
        //         return;
        //     }
        // }
        if (step === 2) {
            if (!isPaymentCompleted) {
                setValidationError('Please pay to continue.'); // Show validation error
                return;
            }
        }
        if (step === 3) {
            Swal.fire({
                title: 'Process Completed!',
                text: 'You have successfully completed the process. Redirecting to login page...',
                icon: 'success',
                confirmButtonText: 'Go to Login',
            }).then(() => {
                navigate('/login'); // Redirect to the login page
            });
            return;
        }
        // setError('')
        setLoading(true);
        setTimeout(() => {
            setStep(step + 1);
            setLoading(false);
        }, 500);
    };
    const [StripeError, setStripeError] = useState(false);
    const [createStripeClicked, setCreateStripeClicked] = useState(false);
    const [linkStripeClicked, setLinkStripeClicked] = useState(false);

    const [Disable, setDisable] = React.useState(false);

    const styles = {
        container: {
            minHeight: '100vh',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
        },
        backgroundImageContainer: {
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            backgroundColor: '#fff',
            width: '100%',
            height: '100%',
            backgroundImage: 'url("/mavesory.png")',
            backgroundSize: '45%',
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat',
            zIndex: -3,
        },
        innerContainer: {
            backgroundColor: '#fff',
            borderRadius: '20px',
            padding: '20px',
            maxWidth: '540px',
            width: '100%',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderTop: '3px solid rgba(0, 0, 0, 0.1)',
            animation: 'fadeIn 0.5s ease-in-out',
        },
        formTitle: {
            color: '#2C3E2D',
            marginBottom: '20px',
            fontWeight: 'bold',
            display: 'flex'
        },
        textField: {
            borderRadius: '4px',
            marginBottom: '10px',
            '& .MuiOutlinedInput-root': {
                background: 'green', // Green background color for the input
                borderRadius: '4px',
                height: '40px', // Set height of the input
                '& fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23)', // Optional: Adjust border color
                },
                '&:hover fieldset': {
                    borderColor: 'darkgreen', // Optional: Adjust hover border color
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'darkgreen', // Optional: Adjust focus border color
                },
            },
            '& .MuiInputBase-input': {
                fontSize: '14px', // Adjust font size
                // padding: '10px', // Add padding for better alignment
            },
        },
        nextButton: {
            background: '#7A9483',
            color: 'white',
            padding: '10px 30px',
            borderRadius: '20px',
            marginTop: '20px',
            '&:hover': {
                background: '#658570',
            },
        },
        card: {
            marginBottom: '10px',
            cursor: 'pointer',
            backgroundColor: '#a1c2a2',
            borderRadius: '10px',
            alignItems: 'center',
            transition: 'all 0.3s ease',
        },
        selectedCard: {
            border: '5px solid #b3946d',
        },
        button: {
            width: "200px",
            backgroundColor: "#a1c2a2",
            color: "white",
            padding: "10px 0",
            borderRadius: "5px",
            margin: '5px'
        },
        errorMessage: {
            color: 'red',
            textAlign: 'center',
            marginTop: '10px',
        },
        cardContainer: {
            padding: '15px',
            background: '#FFFFFF',
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
            margin: '3px',
            marginBottom: '20px',
        },
        payButton: {
            background: '#8C7A63',
            color: '#FFFFFF',
            fontSize: '16px',
            padding: '10px 20px',
            border: 'none',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40px',
            maxWidth: '200px',
            borderRadius: '5px',
            fontWeight: 'bold',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            cursor: Disable ? 'not-allowed' : 'pointer',
            opacity: Disable ? 0.6 : 1, // Adjust opacity for disabled state
        },
    };

    const animations = `
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;
    const [createStripe, setcreateStripe] = React.useState('');
    const [linkStripe, setlinkStripe] = React.useState('');
    const handleCreateStripe = () => {
        if (useremail) {
            setLoading(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/Users/createStripeAccount?email=${useremail}`)
                .then(response => {
                    setcreateStripe(response.accountLinkUrl);
                    setLoading(false);
                    setCreateStripeClicked(true);
                    Swal.fire({
                        title: 'Stripe Account Created',
                        text: 'You can now link your Stripe account.',
                        icon: 'success',
                        confirmButtonText: 'Okay',
                    });
                })
                .catch((error) => {
                    console.error("Error creating Stripe account:", error);
                    setLoading(false);
                    Swal.fire({
                        title: 'Error',
                        text: 'There was an issue creating the Stripe account.',
                        icon: 'error',
                        confirmButtonText: 'Try Again',
                    });
                });
        }
    };

    const handleLinkStripe = () => {
        if (useremail) {
            setLoading(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/Users/linkStripeAccount?email=${useremail}`)
                .then(response => {
                    setlinkStripe(response.accountLinkUr);
                    setLoading(false);
                    setLinkStripeClicked(true);
                    Swal.fire({
                        title: 'Stripe Account Linked',
                        text: 'Your Stripe account has been successfully linked.',
                        icon: 'success',
                        confirmButtonText: 'Okay',
                    });
                })
                .catch((error) => {
                    console.error("Error linking Stripe account:", error);
                    setLoading(false);
                    Swal.fire({
                        title: 'Error',
                        text: 'There was an issue linking the Stripe account.',
                        icon: 'error',
                        confirmButtonText: 'Try Again',
                    });
                });
        }
    };
    const handleDelete = () => {
        navigate("/login");
        // if (useremail) {
        //     axios
        //         .delete(`${process.env.REACT_APP_API_URL}/Users/delete-incompelete?email=${useremail}`)
        //         .then(response => {
        //             navigate("/login");
        //         })
        //         .catch(error => {
        //             navigate("/login");
        //         });
        // }
    };
    const [isPaymentCompleted, setIsPaymentCompleted] = React.useState(false);
    const [validationError, setValidationError] = React.useState('');

    const stripe = useStripe();
    const elements = useElements();
    const handleSubmitPay = async (event) => {
        event.preventDefault();
        setValidationError('');
        setLoading(true);
        if (!stripe || !elements) {
            setLoading(false);
            return;
        }
        const cardElement = elements.getElement(CardElement);
        try {

            const { error, token } = await stripe.createToken(cardElement, { currency: 'usd' });
            // const postData = {
            //   userEmail: emailofUser,
            //   tokenId: token.id,
            //     };
            if (error) {
                setLoading(false);
                return;
            }
            console.log('Token', token.id);


            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/Stripe/add-card-to-customer?userEmail=${useremail}&tokenId=${token.id}`,
            );
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'success',
                    text: 'Card added successfully!',
                });

            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'success',
                    text: response.message,
                });
            }
            setDisable(true)
            setIsPaymentCompleted(true);
        } catch (apiError) {
            console.error('[API Error]', apiError);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: apiError.response?.data?.message || 'An unexpected error occurred.',
            });
        } finally {
            setLoading(false);
        }
    };
    const [invoiceUrl, setinvoiceUrl] = useState('')
    const [Loadingpak, setLoadingoak] = useState(false)
    useEffect(() => {
        if (step === 3) {
            setLoadingoak(true)
            axios
                .post(`${process.env.REACT_APP_API_URL}/Users/UserSubscription?packId=${pakid}&email=${useremail}`,
                )
                .then((response) => {
                    setinvoiceUrl(response.invoiceUrl)
                    setLoadingoak(false)
                })

                .catch((error) => {
                    setLoadingoak(false)
                    console.error('There was an error!', error)
                })
        }
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [useremail, pakid, step])
    // const userType ='Therapist'
    const filteredPackages = userType === 'Therapist'
        ? packages.slice(0, 2) // First 2 rows for therapists
        : packages.slice(2, 4); // Next 2 rows for others

    return (
        <React.Fragment>
            <style>{animations}</style>
            <Container maxWidth={false} style={styles.container}>
                <div style={styles.backgroundImageContainer}></div>
                {loading && <LinearProgress style={{ width: '100%', position: 'absolute', top: 0, color: 'green' }} />}

                {step === 1 && (
                    <Box id="Subscription-container" style={styles.innerContainer}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%", // Adjust width as needed
                            }}
                        >
                            <Typography variant="h5" style={{ margin: '3px', fontWeight: 'bold' }}>
                                Subscriptions
                            </Typography>
                            <IconButton
                                onClick={handleDelete}
                                style={{
                                    backgroundColor: "#8C7A63",
                                    color: "white",
                                    border: "3px solid #fff",
                                    padding: "6px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    marginTop: '-5px',
                                    float: 'right',
                                    alignItems: 'right',
                                    margin: '3px',
                                    justifyContent: "right",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                                    transition: "transform 0.2s, background-color 0.2s",
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        {loading ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="200px"
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>

                                {/* Display packages if loaded */}
                                {filteredPackages && filteredPackages.length > 0 ? (
                                    filteredPackages.map((pkg) => (
                                        <Card
                                            key={pkg.id}
                                            onClick={() => handleCardClick(pkg.duration, pkg.stripeProductId, setpakid(pkg.id))}
                                            sx={{
                                                ...styles.card,
                                                ...(selectedPackage?.duration === pkg.duration && styles.selectedCard),
                                            }}
                                        >
                                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                <Typography style={{ fontWeight: 'bold' }}>{pkg.packageName}</Typography>
                                                {pkg.id === 5 ? (
                                                    <Typography>{`$65.99`}</Typography>
                                                ) :''}
                                                <Typography>({`$${pkg.price} + TAX`})</Typography>
                                            </CardContent>
                                        </Card>
                                    ))
                                ) : (
                                    <Typography>No packages available</Typography>
                                )}
                                {error && <Typography style={styles.errorMessage}>{error}</Typography>}
                                <Button
                                    variant="contained"
                                    fullWidth
                                    style={styles.nextButton}
                                    onClick={handleNext}
                                >
                                    Subscribe
                                </Button>
                            </>
                        )}

                    </Box>
                )}

                {/* {step === 2 && (
                    <Box id="Stripe-container" style={styles.innerContainer}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%", // Adjust width as needed
                            }}
                        >
                            <Typography variant="h5" style={{ margin: '3px', fontWeight: 'bold' }}>
                                Stripe
                            </Typography>
                            <IconButton
                                onClick={handleDelete}
                                style={{
                                    backgroundColor: "#8C7A63",
                                    color: "white",
                                    border: "3px solid #fff",
                                    padding: "6px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    marginTop: '-5px',
                                    float: 'right',
                                    alignItems: 'right',
                                    margin: '3px',
                                    justifyContent: "right",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                                    transition: "transform 0.2s, background-color 0.2s",
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>

                        < TextField
                            label="Account ID"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ style: { height: '40px' } }}
                        />
                        <Button onClick={handleCreateStripe} variant='contained' style={styles.button} >
                            Create Stripe
                        </Button>
                        <Button onClick={handleLinkStripe} variant='contained' style={styles.button}>
                            Link Stripe
                        </Button>
                        <Box
                            style={{
                                marginTop: "20px",
                                padding: "15px",
                                backgroundColor: "#f9f9f9",
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <Typography
                                variant="body1"
                                style={{
                                    fontSize: "14px",
                                    color: "#333",
                                    lineHeight: "1.6",
                                    fontWeight: "500",
                                }}
                            >
                                <strong>Note:</strong> Click <strong>"Create Stripe"</strong> to establish a new Stripe connection.
                                Alternatively, if you already have an account, enter your <strong>Account ID</strong> in the input field above
                                and click <strong>"Link Stripe"</strong> to link your existing account. Ensure all details are accurate
                                before proceeding.
                            </Typography>
                        </Box>
                        {loading ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="200px"
                            >
                                <CircularProgress />
                            </Box>
                        ) : null}
                        {createStripe && (
                            <a
                                href={createStripe}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: "inline-block",
                                    padding: "10px 15px",
                                    background: "#007bff",
                                    color: "#fff",
                                    textDecoration: "none",
                                    borderRadius: "4px",
                                }}
                            >
                                Create Stripe Account
                            </a>
                        )}
                        {linkStripe && (
                            <a
                                href={linkStripe}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: "inline-block",
                                    padding: "10px 15px",
                                    background: "#007bff",
                                    color: "#fff",
                                    textDecoration: "none",
                                    borderRadius: "4px",
                                }}
                            >
                                linkStripe Your Stripe Account
                            </a>
                        )}
                        {StripeError && <Typography style={styles.errorMessage}>To proceed, please create or link your Stripe account.</Typography>}
                        <Button
                            variant="contained"
                            fullWidth
                            style={styles.nextButton}
                            onClick={handleNext}

                        >
                            NEXT
                        </Button>
                    </Box>
                )} */}
                {step === 2 && (
                    <Box style={styles.innerContainer}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%", // Adjust width as needed
                            }}
                        >
                            <Typography variant="h5" style={{ margin: '3px', fontWeight: 'bold' }}>
                                Subscription
                            </Typography>
                            <IconButton
                                onClick={handleDelete}
                                style={{
                                    backgroundColor: "#8C7A63",
                                    color: "white",
                                    border: "3px solid #fff",
                                    padding: "6px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    marginTop: '-5px',
                                    float: 'right',
                                    alignItems: 'right',
                                    margin: '3px',
                                    justifyContent: "right",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                                    transition: "transform 0.2s, background-color 0.2s",
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} mb={2}>
                                <InputLabel style={{ fontWeight: 'bold', color: 'black', textAlign: 'left' }}>Email</InputLabel>
                                <TextField
                                    size="small"
                                    placeholder="Enter Email"
                                    InputProps={{
                                        readOnly: true,
                                        style: styles.emailField,
                                    }}
                                    value={useremail}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={11.9} style={styles.cardContainer}>
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#9E9E9E',
                                                },
                                            },
                                            invalid: {
                                                color: '#F44336',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} mt={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <Button
                                    fullWidth
                                    style={styles.payButton}
                                    disabled={Disable == true}
                                    onClick={handleSubmitPay}
                                >
                                    Pay
                                </Button>
                            </Grid>
                        </Grid>
                        {validationError && (
                            <Typography style={styles.errorMessage}>{validationError}</Typography>
                        )}
                        <Button
                            variant="contained"
                            fullWidth
                            style={styles.nextButton}
                            onClick={handleNext}
                        // disabled={!selectedPackage} // Disable if no package is selected
                        >
                            NEXT
                        </Button>
                    </Box>
                )}
                {step === 3 && (
                    <Box style={styles.innerContainer}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%", // Adjust width as needed
                            }}
                        >
                            <Typography variant="h5" style={{ margin: '3px', fontWeight: 'bold' }}>
                                Invoice
                            </Typography>
                            <IconButton
                                onClick={handleDelete}
                                style={{
                                    backgroundColor: "#8C7A63",
                                    color: "white",
                                    border: "3px solid #fff",
                                    padding: "6px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    marginTop: '-5px',
                                    float: 'right',
                                    alignItems: 'right',
                                    margin: '3px',
                                    justifyContent: "right",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                                    transition: "transform 0.2s, background-color 0.2s",
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Typography
                            mb={2}
                            style={{
                                color: "black",
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                        >
                            Thank You For Purchasing
                        </Typography>
                        <Grid item xs={12}
                            style={{ textAlign: 'center' }}

                        >

                            {Loadingpak ? (
                                <div >
                                    <CircularProgress />
                                </div>) : (
                                <>
                                    {invoiceUrl && (
                                        <a
                                            href={invoiceUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                display: "inline-block",
                                                padding: "10px 15px",
                                                textAlign: 'center',
                                                background: "#007bff",
                                                color: "#fff",
                                                textDecoration: "none",
                                                borderRadius: "4px",
                                            }}
                                        >
                                            Download invoice
                                        </a>
                                    )}
                                </>
                            )}
                        </Grid>
                        <Button style={styles.nextButton} onClick={handleNext} fullWidth>
                            NEXT
                            <KeyboardDoubleArrowRightIcon />
                        </Button>
                    </Box>
                )}
            </Container>
        </React.Fragment >
    );
};

export default ReniewSubscrition;
