// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import { useAlert } from 'react-alert'
import ModeEditIcon from '@mui/icons-material/Edit'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import { Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField, Dialog, DialogActions, DialogContent, DialogContentText, } from '@mui/material';
import { FileUploader } from "react-drag-drop-files";
import ReactSelect from 'react-select'
import LoadingButton from '@mui/lab/LoadingButton'
import { Input } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle';

import CircularProgress from '@mui/material/CircularProgress'
import { Controller, useForm } from 'react-hook-form'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import AddIcon from '@mui/icons-material/Add';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Download from '@mui/icons-material/Download'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import PermIdentity from '@mui/icons-material/PermIdentity'
import WorkOutline from '@mui/icons-material/WorkOutline'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import { DataGrid } from '@mui/x-data-grid';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
// import Avatar from 'react-avatar'
import Avatar from '@mui/material/Avatar';
import fileDownload from 'js-file-download';
import Tooltip from '@mui/material/Tooltip';
import Moment from 'moment';
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Documents = (props) => {
  document.title = "Documents | Maevisory";

  // console.log('Documents List _________________________+++=', props)
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const fileTypes = ['JPEG', 'PNG', 'GIF']
  const alert = useAlert()
  const [tabValue, settabValue] = React.useState(0)
  const [docslist, setdocslist] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [docError, setdocError] = useState(false)
  const [DateError, setDateError] = useState(false)
  // const handleClosee = () => setOpen(false)
  const [submitting, setSubmitting] = useState(false)
  const [date, setdate] = useState('')
  const [base64, setbase64] = useState('')
  const [patientId, setpatientId] = useState(props.patientid !== undefined ? props.patientid : 0)
  const [columnID, setcolumnID] = useState(props.columnID !== undefined ? props.columnID : 0)
  console.log('columnIDdoc', columnID)
  const [AutpattientID, setAutpattientID] = useState(props.AutpattientID)
  console.log('test', AutpattientID)
  // const columnID = props.columnID
  const type = props.type
  const [doctype, setdocType] = useState("")

  console.log('type', type)
  const [opendocs, setopendocs] = useState(false)
  const [urlFile, seturlFile] = useState('')
  const [urldownload, seturldownload] = useState('')
  const [DocName, setDocName] = useState('')
  const [FullDocName, setFullDocName] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [file, setFile] = useState(null)

  const [filename, setfilename] = useState([])
  const [base64Data, setBase64Data] = useState([])
  const [totalProgress, setTotalProgress] = React.useState(0)
  const [savedFiles, setSavedFiles] = React.useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const inputRef = useRef(null)


  const fields = [
    'id',
    'documentName',
    'fileUrl',
    'patientId',
    'practiceId',
    'deleted',
    'date',
    'createdBy',
    'clientCreatedDate',
    'updatedBy',
    'clientUpdatedDate',
    'createdDate',
    'updatedDate',
    'base64',
    'authId',
    'type',
    'documentType',
    'checkvisibel'
  ]
  const defValues = {
    id: 0,
    documentName: '',
    fileUrl: '',
    patientId: 0,
    practiceId: '',
    date: '',
    createdBy: '',
    Deleted: false,
    clientCreatedDate: '',
    updatedBy: '',
    clientUpdatedDate: '',
    createdDate: '',
    updatedDate: '',
    base64: '',
    authId: columnID,
    type: type,
    documentType: '',
    checkvisibel: ''
  }

  const appointmentDocslist = [
    {
      field: 'documentName',
      headerName: 'Document Name',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: cellValues => (
        <>
          <Tooltip title={`${cellValues.value}`} placement='left'>
            <div>
              <Button
                onClick={() => {
                  setopendocs(true)
                  var url = cellValues.row.fileUrl
                  console.log('testurl', url)
                  seturldownload(cellValues.row.fileUrl)
                  // seturlFile(`${process.env.REACT_APP_Document_URL}/${url}`)
                  axios
                    .get(`${process.env.REACT_APP_API_URL}/UserDocument/ViewPatientDocument?path=${url}`, {
                      headers,
                    })
                    .then(response => {
                      seturlFile(response)
                    })
                    .catch(error => {
                      console.error('There was an error!', error)
                    })
                  var arr = cellValues.row.documentName.split('.')
                  console.log('CheckAAAAA', cellValues.row.fileUrl)
                  setDocName(arr.length > 0 ? arr[1].toLowerCase() : '')
                  setFullDocName(cellValues.row.documentName)
                }}
              >{`${cellValues.value}`}</Button>
            </div>
          </Tooltip>
        </>
      )
    },
    {
      field: 'documentType',
      headerName: 'Type',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.5
    },
    {
      field: 'date',
      headerName: 'Document Date',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.5
    },
    // {
    //   field: 'deleted',
    //   headerName: 'Delete',
    //   sortable: true,
    //   headerClassName: 'super-app-theme--header',
    //   minWidth: 0.5,
    //   renderCell: cell => (
    //     <DeleteIcon
    //       style={{
    //         marginRight: '5px',
    //         float: 'right',
    //         marginTop: '5px',
    //         color: 'red',
    //         cursor: 'pointer',
    //         zIndex: 250000,
    //       }}
    //       onClick={() => {
    //         Swal.fire({
    //           title: 'Do you want to delete the record?',
    //           text: 'This action cannot be undone.',
    //           icon: 'warning',
    //           showCancelButton: true,
    //           confirmButtonColor: '#d33',
    //           cancelButtonColor: '#3085d6',
    //           confirmButtonText: 'Yes',
    //           cancelButtonText: 'No',
    //           target: document.querySelector('#hello-world'),
    //         }).then((result) => {
    //           if (result.isConfirmed) {
    //             const postData = {
    //               documentName: cell.row.documentName,
    //               date: cell.row.date,
    //               id: cell.row.id,
    //               deleted: true,
    //             };

    //             axios
    //               .post(`${process.env.REACT_APP_API_URL}/UserDocument/userDocument`, postData, {
    //                 headers,
    //               })
    //               .then(response => {
    //                 setRefresh(!refresh);
    //                 Swal.fire(
    //                   {
    //                     title: 'Deleted',
    //                     text: 'Your file has been deleted.',
    //                     icon: 'success',
    //                     target: document.querySelector('#hello-world'),
    //                   }

    //                 );
    //                 // Handle any other actions after successful delete
    //               })
    //               .catch(error => {
    //                 console.error('There was an error!', error);
    //               });
    //           }
    //         });
    //       }}
    //     />
    //   ),
    // }
    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.14,
      renderCell: cellValues => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '-1px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={() => handleOpenModalDel(cellValues.row)}
        />
      )
    }

  ]

  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const handleOpenModalDel = (row) => {
    setDeleteRow(row);
    setDeleteAlert(true);
  };

  const handleCloseModal = () => {
    setDeleteAlert(false);
    setDeleteRow(null);

  };
  const handleDelete = () => {
    const postData = {
      documentName: deleteRow.documentName,
      date: deleteRow.date,
      id: deleteRow.id,
      deleted: true,
    };
    axios.post(`${process.env.REACT_APP_API_URL}/UserDocument/userDocument`, postData, { headers })
      .then(() => {
        setRefresh(!refresh);
        handleCloseModal();
      })
      .catch(error => {
        console.error('There was an error!', error);
        handleCloseModal();
      });
  };
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: defValues
  })

  const handleOpenn = () => {
    setOpen(true)
    setSubmitting(false)
    setbase64('')
    setfilename('')
  }

  const handleChange = (event, newValue) => {
    settabValue(newValue)
  }
  const navigate = useNavigate();
  // console.log('Reading------------------------------------', urlFile)
  const handleDetails = () => { }
  function applyRefresh() {
    setRefresh(!refresh)
    // setOpen(false)
  }
  const handleClose = () => {
    setopendocs(false)
    setFile(null)
    setOpen(false)
    setSubmitting(false)
    // setopenAppcreate(false)
    setRefresh(true)
  }

  // list of documents
  useEffect(() => {
    setSpinner(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/UserDocument/userDocumentList?userId=${patientId}&type=${type} `, { headers })
      .then((response) => {
        fields.forEach(field => {
          setValue(field, response[field])
        })
        setSpinner(false)
        setdocslist(response)
        setdocType(response.documentType)
      })

      .catch(error => {
        setSpinner(false)
        console.error('There was an error!', error)
      })
  }, [refresh, columnID])

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }
  const styleofmodal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 950,
    // height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #377562',
    boxShadow: 24,
    p: 4
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    // height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #377562',
    boxShadow: 24,
    p: 4,
    scroll: 'auto'
  }

  // document Uplaod
  const fileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  ///////////////////////////////////
  //Single and multiple files
  const handleChangeforfile = async files => {
    const newFilenames = []
    const newBase64Data = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const base64 = await fileToBase64(file)
      newFilenames.push(file.name)
      newBase64Data.push(base64)
    }

    setfilename(newFilenames)
    setBase64Data(newBase64Data)
    setdocError(false)
  }


  // onSubmit for single and multiple files
  const onSubmit = data => {
    if (base64Data.length <= 0) {
      setdocError(true)
      return
    }
    if (!date) {
      setDateError(true)
      return
    }

    setdocError(false)
    setSubmitting(true)
    setSavedFiles([])

    const postData = []
    let totalLength = 0
    for (let i = 0; i < base64Data.length; i++) {
      const documentData = {

        userId: patientId,
        // patientId:AutpattientID,
        date: date,
        documentName: filename[i],
        base64: base64Data[i],
        documentType: doctype,
        type: type,
        checkvisibel: data.checkvisibel
      }

      postData.push(documentData)
      totalLength += base64Data[i].length
    }

    const requests = postData.map((documentData, index) => {
      const config = {
        onUploadProgress: function (progressEvent) {
          const progress = Math.round((progressEvent.loaded / totalLength) * 1.5)
          if (progress <= 100) {
            setTotalProgress(progress)
          } else {
            setTotalProgress(100)
          }
        },
        headers
      }
      return axios
        .post(`${process.env.REACT_APP_API_URL}/UserDocument/userDocument`, documentData, config,)
        .then(response => {
          // Update the savedFiles state with the index of the saved file
          setSavedFiles(savedFiles => [...savedFiles, index])

          setSpinner(false)
          setOpen(false)
          setSubmitting(false)
          if (response) {
            // toast.success('Document uploaded successfully.', { 
            //   position: "top-center",
            //   hideProgressBar: false,
            //   closeOnClick: false,
            //   pauseOnHover: false,
            //   draggable: false,
            //   progress: undefined,
            //   theme: "light",
            alert.success('Document uploaded successfully.', {
              type: 'success',
              onClose: () => {
                // if (props.callingFrom == 'Patient') {
                //   props.updateHealthHistory(response.id, response.authId)
                //   console.log('checktype', response.authId)
                //   props.handleClose222()
                // }
                // else if (props.callingFrom == 'Patient1') {
                //   props.updateHealthHistory(response.id, response.authId)
                //   console.log('checktype', response.authId)
                //   props.handleClose()
                // }
                applyRefresh()

              }
            })
          }


          else {
            alert.error('Error uploading one or more documents.', {
              type: 'error'
            })
          }
        })
        .catch(error => {
          setSpinner(false)
          console.log(error)
          setSubmitting(false)
          toast.error(`Error ${error.message}`, {
            type: 'error'
          })
        })
    })


  }
  const handleDownloadPDF = (url, filename) => {
    setSpinner(true)
    setSubmitting(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/ProviderDocument/DownloadAWSFile?path=${url}`, {
        headers,
        responseType: 'blob',
      })
      .then((response) => {
        setopendocs(false)
        setSpinner(false)
        setSubmitting(false)
        const fileExtension = filename.split('.').pop().toLowerCase();
        const blobUrl = window.URL.createObjectURL(new Blob([response]));
        const fileName =
          fileExtension === 'pdf'
            ? 'file.pdf'
            : `image.${fileExtension}`; // Use dynamic naming for images
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', fileName); // Dynamically set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };
  return (
    <div className="page-content">
      <Container style={{padding:'10px'}} >
        <Dialog
          open={deleteAlert}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontWeight: 'bolder' }} id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ fontWeight: '200' }}>
              Do you want to delete the record?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} variant="contained" style={{ background: '#377562' }}>
              No
            </Button>
            <Button onClick={handleDelete} variant="contained" style={{ background: '#377562' }} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid item spacing={1} xs={12} sm={12} md={12} lg={12}>
              <Button
                className="Button"
                sx={{ mb: 2 }}
                onClick={handleOpenn}
                variant='gradient'
                color='info'
                size='small'
                style={{
                  width: '150px',
                  float: 'right',
                  height: '33px',
                  // backgroundColor: '#A574FD',
                  color: 'white',
                  fontSize: '14px',
                  marginRight: '15px'
                }}
              >
                <AddOutlinedIcon
                  fontSize='medium'
                  style={{
                    color: 'white',
                    paddingRight: '4px',
                    paddingBottom: '2px'
                  }}
                ></AddOutlinedIcon>
                Add New
              </Button>

              <Button
                className="Button"
                sx={{ mb: 2, marginRight: '20px' }}
                onClick={event => {
                  applyRefresh()
                }}
                style={{
                  marginBottom: '10px',
                  float: 'right',
                  marginLeft: '20px',
                  width: '150px',
                  height: '35px',
                  // backgroundColor: '#A574FD',
                  color: 'white',
                  fontSize: '14px'
                }}
              >
                <CachedOutlinedIcon
                  fontSize='medium'
                  style={{
                    color: 'white',
                    marginRight: '1px',
                    paddingRight: '0px',
                    paddingBottom: '2px'
                  }}
                ></CachedOutlinedIcon>
                Refresh
              </Button>
            </Grid>
            {/* {spinner ? (
              <FallbackSpinner
                style={{
                  width: '50px',
                  height: '50px',
                  position: 'absolute',
                  left: '50%',
                  top: '50%'
                  // marginLeft: '-25px'
                  // marginTop: '-25px'
                }}
              />
            ) : ( */}
            <>

              <Box mb={1}>
                <Grid container xs={12} sm={12} md={12} lg={12} style={{ height: 390, width: '100%' }}>
                  {spinner ? (
                    <div style={{ margin: '5rem', textAlign: 'center', width: '100%' }}>
                      <CircularProgress />
                    </div>) : (
                    <div mb={0} style={{ height: 400, width: '100%' }}>
                      {docslist === null || docslist.length === 0 ? (
                        "No Record Found"
                      ) : (
                        <DataGrid
                          rows={docslist}
                          columns={appointmentDocslist}
                          headerHeight={37}
                          sx={gridRowStyle}
                          pagination
                          autoPageSize
                        />
                      )}
                    </div>
                  )}
                </Grid>
              </Box>
            </>
            {/* )} */}
          </Grid>
        </Grid>

        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={open}
          onClose={handleClose}
          closeAfterTransition
        // BackdropProps={{
        //   timeout: 500
        // }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                  <CancelOutlinedIcon
                    onClick={handleClose}
                    fontSize='medium'
                    color='red'
                    cursor='pointer'
                    style={{ color: 'red', marginTop: '-30', float: 'right', }}
                  ></CancelOutlinedIcon>
                </Grid>
                <Grid item xs={12} style={{ fontWeight: 500, backgroundColor: '#377562', marginTop: '-15', }}>
                  <Typography style={{ fontWeight: 'bold', fontSize: '18px', color: 'white', }}>
                    Upload Document
                  </Typography>
                </Grid>
                {/* {props.callingFrom == 'Patient' ? (

                <Grid item xs={5}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography color='black' variant='caption' fontSize='15px' fontWeight='bold'>
                        Type:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography color='black' variant='caption' fontSize='15px'>
                        {props.type}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (null)} */}

              </Grid>
              {/* </Grid> */}
              <Grid container spacing={1}>
                <Grid item xs={5} style={{ marginTop: 15, }}>
                  <TextField
                    type='date'
                    size='small'
                    value={date}
                    onChange={e => {
                      setdate(e.target.value)
                      setDateError(false)
                    }}
                    width='auto'
                    fullWidth
                    placeholder='Select date'
                  />
                  {DateError ? <Typography style={{ color: 'red' }}>Please select date</Typography> : null}
                </Grid>
                <Grid item xs={1} style={{ marginTop: 15, }}>
                  <Typography style={{ fontSize: '14px', color: 'black', marginTop: '8px' }}>
                    Type:
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ marginTop: 15, }}>
                  <Controller
                    name='documentType'
                    margin='dense'
                    {...register('documentType')}
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        style={{ marginTop: 0, marginBottom: 0 }}

                        {...field}
                        options={[
                          { value: 'Supervisor Agreement', label: 'Supervisor Agreement' },
                          { value: 'Jurisprudence Exam', label: 'Jurisprudence Exam' },
                          { value: 'NCE/NCMHCE Scores', label: 'NCE/NCMHCE Scores' },
                          { value: 'Transcripts', label: 'Transcripts' },
                          { value: 'Provisional License', label: 'Provisional License' },


                        ]}
                        value={{ label: getValues('documentType') }}
                        // value={type ? type : ''}
                        // onChange={e => {
                        //   setValue('type', e.target.value)
                        //   setType(e.target.value)
                        // }}

                        onChange={value => {
                          setValue('documentType', value.label)
                          setdocType(value.label)
                        }}
                        size='small'
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" spacing={2} style={{ marginTop: '10px' }}>
                <Grid item xs={4}>
                  <Button className="Button" variant="contained" component="label">
                    Upload
                    <input
                      type="file"
                      name="file"
                      onInput={e => handleChangeforfile(e.target.files)}
                      style={{ display: 'none' }}
                      multiple
                    />
                  </Button>
                </Grid>
                <Grid item XS={2}>
                  <input
                    type="checkbox"
                    {...register("checkvisibel")}
                    style={{
                      marginLeft: "10px",
                      transform: "scale(1.0)",
                      marginRight: "5px",
                      marginTop: '5px',
                      fontWeight: 'bold'
                    }}
                  />
                  <label style={{ fontSize: '14px', color: 'black', marginTop: '8px' }}>
                    Visible to Supervisee
                  </label>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 15, }}>
                <FileUploader multiple={true} handleChange={handleChangeforfile} name='file' />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '5px' }}>
                <Typography> To Upload files Please click on upload button and then Save it</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '5px' }}>
                <>
                  {filename.length > 0 && (
                    <div>
                      <h2>Uploaded Files:</h2>
                      <div style={{ height: '100px', width: '100%', }}>
                        <ul>
                          {filename.map((name, index) => (
                            <>
                              {/* <li key={name}>{name} </li> */}
                              <li>
                                <Typography key={index} variant='body1' color='black'>
                                  {name} {savedFiles.includes(index) && '✓'}
                                </Typography>
                              </li>
                            </>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '5px' }}>
                {file && <p>Currently uploading: {file.name}</p>}
              </Grid>
              <Grid>
                {docError ? <Typography style={{ color: 'red' }}>Please select document and date</Typography> : null}
              </Grid>
              <Grid item style={{ fontWeight: 500, width: 250 }}>
                {submitting ? (
                  <Grid>
                    {/* <LoadingButton loading loadingIndicator='Saving…' color='primary' variant='contained'>
                    Fetch data
                  </LoadingButton> */}

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>

                    <Typography variant='bold' component='div' color='#ba000d'>
                      Please wait your data is being saved..
                    </Typography>
                  </Grid>
                ) : (
                  <Button className="Button" variant='contained' color='primary' onClick={handleSubmit(onSubmit)} disabled={submitting} style={{ width: '80px' }}>
                    Save
                  </Button>
                )}

                <Button
                  className="Button"
                  variant='contained'
                  style={{ backgroundColor: 'primary', color: 'white', marginLeft: 10, width: '80px' }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Grid>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          onClose={handleClose}
          open={opendocs}
        >
          <Box sx={styleofmodal}>
            <Grid container>
              <Grid item xs={9}>
                <Typography id='modal-modal-title' variant='h6' component='h2' style={{ fontWeight: 500, backgroundColor: '#377562', marginBottom: 5, color: 'white' }}>
                  {FullDocName}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {submitting ? (
                  <LoadingButton
                    loading
                    loadingIndicator='Downloading'
                    // style={{ position: 'absolute', right: '28px', bottom: '11px' }}
                    color='primary'
                    variant='contained'
                  >
                    Fetch data
                  </LoadingButton>
                ) : (
                  <Button
                    className="Button"
                    size='small'
                    startIcon={<Download />}
                    onClick={() => {
                      handleDownloadPDF(`${urldownload}`, FullDocName)
                    }}
                    variant='contained'
                    disabled={submitting}
                    style={{
                      // backgroundColor: 'primary',
                      color: 'white',
                      float: 'right'
                      // position: 'absolute',
                      // marginRight: '28px',
                      // bottom: '11px'
                    }}
                  >
                    Download
                  </Button>
                )}
              </Grid>
              <Grid item xs={1}>
                <CancelOutlinedIcon
                  onClick={handleClose}
                  fontSize='large'
                  color='red'
                  cursor='pointer'
                  style={{ color: 'red', marginBottom: 5, float: 'right', }}
                ></CancelOutlinedIcon>

              </Grid>
              {/* <Grid item xs={2}></Grid> */}

              {(DocName && DocName == 'jpg') || DocName == 'png' || DocName == 'jpeg' ? (
                <>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>

                    <img
                      height='500px'
                      width='500px'
                      border='4px solid #000'
                      // frameborder='0'
                      // overflow='hidden'
                      src={`${urlFile}`}
                    // src={`https://images.unsplash.com/photo-1672394423014-e0354d75b123?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60`}
                    ></img>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <iframe
                      height='500px'
                      width='875px'
                      // frameborder='0'
                      // overflow='hidden'
                      src={`${urlFile}`}
                    // src={`https://images.unsplash.com/photo-1672394423014-e0354d75b123?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60`}
                    ></iframe>
                  </Grid>
                </>
              )}
              {/* <Grid item xs={2}></Grid> */}
              <Grid item xs={12}></Grid>
            </Grid>
          </Box>
        </Modal>
      </Container>
    </div>
  )
}
export default Documents
