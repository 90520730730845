import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment
} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Select from "react-select";
import Visibility from '@mui/icons-material/Visibility';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ArrowBack, Subscriptions } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from 'sweetalert2';
import * as Yup from "yup";
import axios from "axios";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const SuperviseeSignUp = (props) => {
  const fields = [
    "id",
    "practiceId",
    "firstName",
    "lastName",
    "createdBy",
    "updatedBy",
    "phone",
    "email",
    "address",
    "city",
    "state",
    "zip",
    "type",
    "password",
    "profilePictureURL",
    "gender",
    "preferredGender",
    "ethnicity",
    "race",
    "licenseType",
    "licenseState",
    "licenseNumber",
    "initialLicensureDate",
    "licenseExpirationDate",
    "supervisortrainingtitle",
    "dateofCompletedTraining",
    "uploadTrainingCertificateURL",
    "liabilityInsuranceCarrier",
    "policyNumber",
    "insurancePolicyStartDate",
    "insurancePolicyEndDate",
    "uploadCopyofInsuranceURL",
    "pdf",
    "inActive",
    "userAggreementCheck",
    "createdDate",
    "updatedDate",
    "black",
    "white",
    "americanIndian",
    "nativeHawaiian",
    "asian",
    "middleEastern",
    "multiracial",
    "other",
    "lpcSupervisor",
    "lcswSupervisor",
    "lmftSupervisor",
    "bcba",
    "licensedSpecialist",
    "psychologist",
    "male",
    "female",
    "nonBinary",
    "intersex",
    "transWoman",
    "transMan",
    "hispanic",
    "notHispanic",
    "lpcAssociate",
    "lmftAssociate",
    "lmswormsw",
    "lcdci",
    "bcbaTrainee",
    "psychologicalAssociate",
    "pendingLicens",
    "directhoursrequired",
    "directhourshave",
    "indirecthoursrequired",
    "indirecthourshave",
    "profilePictureBase64",
    "uploadTrainingCertificateBase64",
    "uploadCopyofInsuranceBase64",
    "supscription",
    "others",
    "sigantures",
  ];
  const defValues = {
    id :0,
    practiceId :0,
    firstName :'',
    lastName :'',
    createdBy :'',
    updatedBy :'',
    phone :'',
    email :'',
    address :'',
    city :'',
    state :'',
    zip :'',
    type :'',
    password :'',
    profilePictureURL :'',
    gender :'',
    preferredGender :'',
    ethnicity :'',
    race :'',
    licenseType :'',
    licenseState :'',
    licenseNumber :'',
    initialLicensureDate :'',
    licenseExpirationDate :'',
    supervisortrainingtitle :'',
    dateofCompletedTraining :'',
    uploadTrainingCertificateURL :'',
    liabilityInsuranceCarrier :'',
    policyNumber :'',
    insurancePolicyStartDate :'',
    insurancePolicyEndDate :'',
    uploadCopyofInsuranceURL :'',
    pdf :'',
    inActive :'',
    userAggreementCheck :'',
    createdDate :'',
    updatedDate :'',
    black :'',
    white :'',
    americanIndian :'',
    nativeHawaiian :'',
    asian :'',
    middleEastern :'',
    multiracial :'',
    other :'',
    lpcSupervisor :'',
    lcswSupervisor :'',
    lmftSupervisor :'',
    bcba :'',
    licensedSpecialist :'',
    psychologist :'',
    male :'',
    female :'',
    nonBinary :'',
    intersex :'',
    transWoman :'',
    transMan :'',
    hispanic :'',
    notHispanic :'',
    lpcAssociate :'',
    lmftAssociate :'',
    lmswormsw :'',
    lcdci :'',
    bcbaTrainee :'',
    psychologicalAssociate :'',
    pendingLicens :'',
    directhoursrequired :'',
    directhourshave :'',
    indirecthoursrequired :'',
    indirecthourshave :'',
    profilePictureBase64 :'',
    uploadTrainingCertificateBase64 :'',
    uploadCopyofInsuranceBase64: "",
    supscription :'',
    others:'',
    sigantures:'',
  };
  const validationSchema = Yup.object({
    firstName: Yup.string().required("Please Enter First Name"),
    directhourshave: Yup.string().required("Please Enter Direct Hours"),
    indirecthourshave: Yup.string().required("Please Enter In-Direct Hours"),
    lastName: Yup.string().required("Please Enter Last Name"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/\d/, 'Password must contain at least one number')
      .matches(/[\W_]/, 'Password must contain at least one special character'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Please confirm your password'),

    licenseNumber: Yup.string()
      .required("License number is required")
      .matches(/^[a-zA-Z0-9]+$/, "License number must be alphanumeric"),
    initialLicensureDate: Yup.date()
      .required("Initial licensure date is required")
      .typeError("Invalid date format"),
    licenseExpirationDate: Yup.date()
      .required("License expiration date is required")
      .typeError("Invalid date format")
      .min(
        Yup.ref("initialLicensureDate"),
        "Expiration date must be later than licensure date"
      ),
    liabilityInsuranceCarrier: Yup.string()
      .required("Liability insurance carrier is required"),
    policyNumber: Yup.string()
      .required("Policy number is required"),
  });


  const {
    register,
    control,
    handleSubmit,
    getValues,
    trigger,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const [showForm, setShowForm] = useState(0);

  const [selectedCard, setSelectedCard] = useState("");
  const [CardError, setCardError] = useState(false);
  const [ProfileError, setProfileError] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const handleCardClick = (type, value) => {
    setSelectedCard(type);
    setCardError(false);
    setSelectedValue(value);
  };

  const [genderError, setGenderError] = useState(false);
  const [preferredGenderError, setPreferredGenderError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleNextClick = async () => {
    if (showForm == 0) {
      if (!selectedValue) {
        setCardError(true)
        return;
      }
    } else if (showForm == 1) {
      const isValid = await trigger("firstName") && await trigger("lastName") && await trigger("email") && await trigger("phone");
      if (!isValid) {
        return;
      }
    } else if (showForm === 2) {
      const isValid = await trigger("password") && await trigger("confirmPassword");
      if (!isValid) {
        return;
      }
    } else if (showForm === 3) {
      if (!selectedImage) {
        setProfileError(true)
        return;
      }
    } else if (showForm === 4) {
      if (!gender) {
        setGenderError(true)
        return;
      }
      if (!preferredGender) {
        setPreferredGenderError(true)
        return;
      }
    } else if (showForm == 5) {
      const isValid = await trigger("licenseNumber") && await trigger("initialLicensureDate") && await trigger("licenseExpirationDate");
      if (!isValid) {
        return;
      }
    } else if (showForm == 6) {
      const isValid = await trigger("directhourshave") && await trigger("indirecthourshave") ;
      if (!isValid) {
        return;
      }
    }
    
    else if (showForm == 7) {
      const isValid = await trigger("liabilityInsuranceCarrier") && await trigger("policyNumber") ;
      if (!isValid) {
        return;
      }
    }
    else if (showForm == 8) {
      if (!base64ImageAgreement) {
        setAgreementError(true)
        return;
      }
    }
    if (showForm < 8) {
      setShowForm(showForm + 1);
    } else if (showForm == 8) {
      onSubmit(getValues());
    }
  };
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }

  const [stateLicenseBase64, setStateLicenseBase64] = useState("");
  const [stateLicenseURL, setStateLicenseURL] = useState("");

  const handleFileChangeState = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setStateLicenseBase64(reader.result); 
        setStateLicenseURL(file.name);
      };
      reader.readAsDataURL(file);
    }
  };
  const [CopyInsurance, setCopyInsurance] = useState("");
  const [CopyInsuranceeURL, setCopyInsuranceeURL] = useState("");

  const handleFileChangeStateInsurance = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCopyInsuranceeURL(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setCopyInsurance(reader.result);
      };
      reader.readAsDataURL(file); // Convert file to Base64
    }
  };

  const HandleCloseModal = () => {
    fields.forEach((field) => setValue(field, ''));
    reset();
    props.setSignUpModalOpen(false)
    props.setOpenSignInPageSupervisee(false)
  };
  const onSubmit = (data) => {
    let postData = {
      ...data,
      supscription: selectedValue,
      type:'Therapist',
      state: selectedState,
      profilePictureBase64: base64Image,
      profilePictureURL: selectedImage,
      gender: gender,
      preferredGender: preferredGender,
      ethnicity: ethnicity,
      licenseState:LicenseState,
      uploadStateLicenseBase64: stateLicenseBase64,
      uploadStateLicenseURL: stateLicenseURL,
      pdf: base64ImageAgreement,
      agreementURL: ImageAgreementUrl,
      uploadCopyofInsuranceBase64: CopyInsurance,
      uploadCopyofInsuranceURL:CopyInsuranceeURL,

    };
    console.log('Click By Muzammil', postData)
    axios
      .post(`${process.env.REACT_APP_API_URL}/Users/SignUp`, postData, { headers })
      .then((response) => {
        if (response.status === 200 && response.practiceId) {
          Swal.fire({
            title: 'Success!',
            text: 'Record Submitted Successfully to Mavesory!',
            icon: 'success',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          HandleCloseModal();
        } else {
          Swal.fire({
            title: 'Error!',
            text: response ,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .finally(() => {
        // Optional: Add cleanup code or additional logic here if needed
      });
  };
  const handleBackClick = () => {
    if (showForm > 0) {
      setShowForm(showForm - 1);
    }
  };

  const nextButtonStyle = {
    border: '3px solid #fff',
    marginTop: '10px',
    borderRadius: "50px",
    padding: "10px 30px",
    backgroundColor: "#8C7A63",
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
  };
  const [selectedState, setSelectedState] = useState(null);
  const [LicenseState, setLicenseState] = useState(null);
  const states = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Maryland", label: "Maryland" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Missouri", label: "Missouri" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Washington", label: "Washington" },
    { value: "West Virginia", label: "West Virginia" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
  ];
  const [selectedImage, setSelectedImage] = useState(null);

  const [base64Image, setBase64Image] = useState("");
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64Image(base64String);
      };
      reader.readAsDataURL(file);
    }
    setProfileError(false)
  };

  const [Agreement, setAgreement] = useState(null);
  const [AgreementError, setAgreementError] = useState(false);

  const [base64ImageAgreement, setBase64ImageAgreement] = useState("");
  const [ImageAgreementUrl, setImageAgreementUrl] = useState("");
  const handleImageUploadAgreement = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageAgreementUrl(file.name)
    const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64ImageAgreement(base64String); // Save Base64 data
        setAgreement(true); // Update agreement state
      };
      reader.readAsDataURL(file); // Reads the file as Base64
    }
    setAgreementError(false)
  };

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const preferredGenderOptions = [
    { value: "Male", label: "Male" },
    { value: "Non-Binary", label: "Non-Binary" },
    { value: "Intersex", label: "Intersex" },
    { value: "Trans Woman", label: "Trans Woman" },
    { value: "Trans Man", label: "Trans Man" },
    { value: "Prefer Not to Say", label: "Prefer Not to Say" },
  ];

  const ethnicityOptions = [
    { value: "Hispanic or Latino", label: "Hispanic or Latino" },
    { value: "Not Hispanic or Latino", label: "Not Hispanic or Latino" },
  ];

  const [gender, setGender] = useState(null);
  const [preferredGender, setPreferredGender] = useState(null);
  const [ethnicity, setEthnicity] = useState(null);
  const [OtherField, setOtherField] = useState(false);

  const MyStyle = () => ({
    control: (base) => ({
      ...base,
      backgroundColor: "#aec3b0",
      borderColor: "#fff",
      color: "black",
      boxShadow: "none",
      "&:hover": { borderColor: "#8C7A63" },
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    placeholder: (base) => ({
      ...base,
      color: "black",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#fff",
      color: "black",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#8C7A63" : "#aec3b0",
      color: "#fff",
    }),
  });

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#cfcccc",
          borderRadius: "10px",
          padding: "20px",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <IconButton
          disabled={showForm == 0}
          style={{
            backgroundColor: "#aec3b0",
            color: "white",
            border: "3px solid #fff",
            padding: "6px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
            transition: "transform 0.2s, background-color 0.2s",
          }}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#8C7A63")}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#aec3b0")}
        >
          <ArrowBack onClick={handleBackClick} />
        </IconButton>
        <Typography variant="h6" mb={2} style={{
          color: '#8C7A63',
          fontWeight: 'bold'
        }}>
          SUPERVISEE SIGN UP
        </Typography>
        {showForm === 0 && (
          <>
            <>
              <Card
                onClick={() => handleCardClick("monthly", "$12.99 + TAX")}
                sx={{
                  marginBottom: "10px",
                  border:
                    selectedCard === "monthly"
                      ? "5px solid #b3946d"
                      : "5px solid transparent",
                  cursor: "pointer",
                  backgroundColor: "#a1c2a2",
                }}
              >
                <CardContent>
                  <Typography>MONTHLY SUBSCRIPTION</Typography>
                  <Typography>$12.99 + TAX</Typography>
                </CardContent>
              </Card>

              <Card
                onClick={() => handleCardClick("yearly", "$120.00 + TAX")}
                sx={{
                  marginBottom: "10px",
                  border:
                    selectedCard === "yearly"
                      ? "5px solid #b3946d"
                      : "5px solid transparent",
                  cursor: "pointer",
                  backgroundColor: "#a1c2a2",
                }}
              >
                <CardContent>
                  <Typography>YEARLY SUBSCRIPTION</Typography>
                  <Typography>$119.88 + TAX</Typography>
                </CardContent>
              </Card>
              {CardError && (
                <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                  Please Select Subscription
                </Typography>
              )}


            </>
          </>
        )}
        {showForm === 1 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <Controller
                  name="firstName"
                  {...register("firstName")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="First Name"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('firstName', e.target.value);
                      }}
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />}
                />
              </Grid>

              <Grid item xs={12} >
                <Controller
                  name="lastName"
                  {...register("lastName")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Last Name"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('lastName', e.target.value);
                      }}
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="phone"
                  {...register("phone")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Phone"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('phone', e.target.value);
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="email"
                  {...register("email")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Email"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('email', e.target.value);
                      }}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="address"
                  {...register("address")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Address"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('address', e.target.value);
                      }}
                    />
                  }
                />
              </Grid>



              <Grid item xs={6}>
                <Controller
                  name="city"
                  {...register("city")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="City"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('city', e.target.value);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="zip"
                  {...register("zip")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Zip"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('zip', e.target.value);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                  Select State
                </Typography>
                <Select
                  options={states}
                  value={states.find(option => option.label === selectedState)}
                  onChange={(option) => {
                    setSelectedState(option.label);  
                  }}
                  placeholder="Select a state"
                  styles={MyStyle()}

                />
              </Grid>
            </Grid>
          </>
        )}
        {showForm === 2 && (
          <>
            <Typography style={{ marginBottom: "10px", textAlign: 'left',fontWeight:'bold' }}>
              USERNAME: <b> {watch("email") || "N/A"}</b> 
            </Typography>
            <Typography style={{ marginBottom: "10px", textAlign: 'left', fontWeight: 'bold' }}>
              CREATE A PASSWORD:
            </Typography>
            <ul style={{ textAlign: 'left' }}>
              <li>At least 8 characters.</li>
              <li>A mixture of both uppercase and lowercase letters.</li>
              <li>A mixture of letters and numbers.</li>
              <li>Inclusion of at least one special character, e.g., ! @ # ? []</li>
              <span> <b>Note:</b> do not us "&lt;or&gt;" in your password, as both can cause problems in Web browsers.
              </span>
            </ul>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                  TYPE PASSWORD BELOW:
                </Typography>

                <Controller
                  name="password"
                  {...register("password")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      // type="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Type Password Below"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onMouseDown={() => setShowPassword(true)}
                              onMouseUp={() => setShowPassword(false)}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          backgroundColor: '#aec3b0',
                          color: 'black',
                          textAlign: 'left',
                          height: '40px',
                        },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('password', e.target.value);
                      }}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                  RE-TYPE PASSWORD BELOW:
                </Typography>
                <Controller
                  name="confirmPassword"
                  {...register("confirmPassword")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      type={showPasswordConfirm ? 'text' : 'password'}
                      placeholder="Re-Type Password Below"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onMouseDown={() => setShowPasswordConfirm(true)}
                              onMouseUp={() => setShowPasswordConfirm(false)}
                            >
                              {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: { backgroundColor: "#aec3b0", color: "black", textAlign: 'left', height: '40px' },
                      }}
                      {...field}
                      fullWidth
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword?.message}
                      onChange={(e) => {
                        setValue('confirmPassword', e.target.value);
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>

          </>
        )}
        {showForm === 3 && (
          <>
            <Box
              sx={{
                backgroundColor: "#aec3b0",
                borderRadius: "10px",
                padding: "20px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <Typography
                mb={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                UPLOAD PROFILE PICTURE
              </Typography>
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  margin: "20px auto",
                  backgroundColor: "#fff",
                  border: '2px dashed grey',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                {selectedImage ? (
                  <img
                    src={base64Image}
                    alt="Uploaded Preview"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                ) : (
                  <label style={{ cursor: "pointer" }}>
                    Please Select Picture

                  </label>
                )}
              </div>
              <div
                style={{
                  margin: "20px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  id="fileInput"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                />
                <IconButton>
                  <FileDownloadOutlinedIcon
                    onClick={() => document.getElementById('fileInput').click()}
                    fontSize="large" color="black" cursor="pointer" fontWeight='bold' />
                </IconButton>
              </div>
              {ProfileError && (
                <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                  Please Select Picture
                </Typography>
              )}
            </Box>
          </>
        )}
        {showForm === 4 && (
          <>
            <Grid container spacing={1} style={{ textTransform: 'uppercase' }}>
              <Grid item xs={12}>
                <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                  Gender
                </Typography>
                <Select
                  options={genderOptions}
                  value={genderOptions.find(option => option.label === gender)}
                  onChange={(option) => {
                    setGender(option.label);  
                  }}
             
                  placeholder="Gender"
                  styles={MyStyle()}

                />
                {genderError && (
                  <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                    Please Select Gender
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                  Preferred Gender
                </Typography>
                <Select
                  options={preferredGenderOptions}
                  value={preferredGenderOptions.find(option => option.label === preferredGender)}
                  onChange={(option) => {
                    setPreferredGender(option.label);  
                  }}
                  placeholder="Preferred Gender"
                  styles={MyStyle()}

                />
                {preferredGenderError && (
                  <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                    Please Select Preferred Gender
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" style={{ color: "black", textAlign: 'left' }}>
                  Ethnicity
                </Typography>
                <Select
                  options={ethnicityOptions}
                  value={ethnicityOptions.find(option => option.label === ethnicity)}
                  onChange={(option) => {
                    setEthnicity(option.label);  
                  }}
                  placeholder="Ethnicity"
                  styles={MyStyle()}

                />
              </Grid>
            </Grid>
            <Box
              sx={{
                backgroundColor: "#aec3b0",
                borderRadius: "10px",
                padding: "10px",
                marginTop: "10px",
                maxWidth: "600px",

              }}
            >
              <Typography style={{ color: "black", fontWeight: "bold", textAlign: 'left', marginBottom: '3px', textTransform: 'uppercase' }}>
                Race
              </Typography>
              <Grid container spacing={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
                <Grid item xs={12} >
                  <input
                    name='black'
                    size='small'
                    type='checkbox'
                    {...register('black')}
                    id='black'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black' }}>Black or African American</lable>
                </Grid>
                <Grid item xs={12} >
                  <input
                  name='white'
                  size='small'
                  type='checkbox'
                  {...register('white')}
                  id='white'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black' }}>White</lable>
                </Grid>
                <Grid item xs={12} >
                  <input
                     name='americanIndian'
                     size='small'
                     type='checkbox'
                     {...register('americanIndian')}
                     id='americanIndian'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black' }}>American Indian or Alaska Native</lable>
                </Grid>
                <Grid item xs={12} style={{ whiteSpace: 'nowrap', }} >
                  <input
                    name='nativeHawaiian'
                    size='small'
                    type='checkbox'
                    {...register('nativeHawaiian')}
                    id='nativeHawaiian'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black' }}>Native Hawaiian or Other Pacific Islander</lable>
                </Grid>
                <Grid item xs={12} >
                  <input
                     name='asian'
                     size='small'
                     type='checkbox'
                     {...register('asian')}
                     id='asian'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black' }}>Asian</lable>
                </Grid>
                <Grid item xs={12} style={{ whiteSpace: 'nowrap', }} >
                  <input
                         name='middleEastern'
                         size='small'
                         type='checkbox'
                         {...register('middleEastern')}
                         id='middleEastern'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black' }}>Middle Eastern or North African (MENA)</lable>
                </Grid>
                <Grid item xs={12} >
                  <input
                         name='multiracial'
                         size='small'
                         type='checkbox'
                         {...register('multiracial')}
                         id='multiracial'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black' }}>Multiracial</lable>
                </Grid>
                <Grid item xs={12} >
                  <input
                    name='other'
                    size='small'
                    type='checkbox'
                    {...register('other')}
                    id='other'
                    style={{
                      backgroundColor: "#8C7A63",
                      color: "#8C7A63"

                    }}
                    onClick={() => setOtherField(true)}
                  />
                  <lable style={{ marginLeft: '3px', color: 'black' }}>Other </lable>
                  {OtherField && (
                     <Controller
                     name="others"
                     {...register("others")}
                     control={control}
                     render={({ field }) =>
                       <TextField
                         size="small"
                         placeholder="other..."
                         InputProps={{
                          style: { backgroundColor: "#8C7A63", color: "#fff", height: '40px' },
                        }}
                         {...field}
                         onChange={(e) => {
                           setValue('others', e.target.value);
                         }}
                       />}
                   />
                  )}
                </Grid>

              </Grid>
            </Box>
          </>
        )}
        {showForm === 5 && (
          <>
            <Box
              sx={{
                backgroundColor: "#aec3b0",
                borderRadius: "10px",
                padding: "10px",
                marginTop: "10px",
                maxWidth: "600px",
              }}
            >
              <Typography style={{ color: "black", fontWeight: "bold", textAlign: 'left', marginBottom: '3px' }}>
                LICENSED TYPE
              </Typography>
              <Grid container spacing={1} style={{ textAlign: 'left' }}>
                <Grid item xs={12} >
                  <input
                   name='lpcAssociate'
                   size='small'
                   type='checkbox'
                   {...register('lpcAssociate')}
                   id='lpcAssociate'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}>LPC Associate</lable>
                </Grid>
                <Grid item xs={12} >
                  <input
                 name='lmswormsw'
                 size='small'
                 type='checkbox'
                 {...register('lmswormsw')}
                 id='lmswormsw'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}>LMSW OR MSW</lable>
                </Grid>
                <Grid item xs={12} >
                  <input
                      name='lcdci'
                      size='small'
                      type='checkbox'
                      {...register('lcdci')}
                      id='lcdci'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}>LCDC-I</lable>
                </Grid>
                <Grid item xs={12} style={{ whiteSpace: 'nowrap', }} >
                  <input
                    name='bcbaTrainee'
                    size='small'
                    type='checkbox'
                    {...register('bcbaTrainee')}
                    id='bcbaTrainee'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase', whiteSpace: 'nowrap', }}>BCBA Trainee</lable>
                </Grid>
                <Grid item xs={12} >
                  <input
                    name='psychologicalAssociate'
                    size='small'
                    type='checkbox'
                    {...register('psychologicalAssociate')}
                    id='psychologicalAssociate'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}>Psychological Associate</lable>
                </Grid>

              </Grid>
            </Box>
            <Grid item xs={12} mt={1} >
              <input
               name='pendingLicens'
               size='small'
               type='checkbox'
               {...register('pendingLicens')}
               id='pendingLicens'
              />
              <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}> Pending License (Looking for Supervisor w/Maevisory)

              </lable>
            </Grid>
            <Grid container spacing={1} mt={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
              <Grid item xs={6}>
                <InputLabel>License State</InputLabel>
                <Select
                  options={states}
                  value={states.find(option => option.label === LicenseState)}
                  onChange={(option) => {
                    setLicenseState(option.label);  
                  }}
                  placeholder="License State"
                  styles={MyStyle()}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>License Number</InputLabel>
                <Controller
                  name="licenseNumber"
                  {...register("licenseNumber")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="License Number"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('licenseNumber', e.target.value);
                      }}
                      error={!!errors.licenseNumber}
                      helperText={errors.licenseNumber?.message}
                    />}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Initial Licensure Date</InputLabel>
                <Controller
                  name="initialLicensureDate"
                  {...register("initialLicensureDate")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Initial Licensure Date"
                      type="date"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('initialLicensureDate', e.target.value);
                      }}
                      error={!!errors.initialLicensureDate}
                      helperText={errors.initialLicensureDate?.message}
                    />}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>License Expiration Date</InputLabel>
                <Controller
                  name="licenseExpirationDate"
                  {...register("licenseExpirationDate")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="License Expiration Date"
                      type="date"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black", height: '40px' },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('licenseExpirationDate', e.target.value);
                      }}
                      error={!!errors.licenseExpirationDate}
                      helperText={errors.licenseExpirationDate?.message}
                    />}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Upload State License</InputLabel>
                <input
                  type="file"
                  accept="image/*,application/pdf"
                  id="Licence"
                  onChange={handleFileChangeState}
                  style={{ display: "none" }}
                />
                <TextField
                  variant="outlined"
                  // type="file"
                  placeholder="Upload State License"
                  value={stateLicenseURL}
                  InputProps={{
                    endAdornment: <FileDownloadOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => document.getElementById("Licence").click()} />,
                    style: { backgroundColor: "#aec3b0", color: "black", textAlign: 'left', height: '40px' },
                  }}
                  fullWidth
                />

              </Grid>
            </Grid>
          </>
        )}
        {showForm === 6 && (
          <>
            <Grid container spacing={1} mt={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
              <Grid item xs={12}>
                <Controller
                  name="directhoursrequired"
                  {...register("directhoursrequired")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      multiline
                      placeholder="How many direct hours are required for your license type?"
                      rows={2}
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black" },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('directhoursrequired', e.target.value);
                      }}

                    />}
                />
              </Grid>
              <Grid item xs={12}>

                <Controller
                  name="directhourshave"
                  {...register("directhourshave")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      multiline
                      placeholder="How many direct hours do you already have?"
                      rows={2}
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black" },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('directhourshave', e.target.value);
                      }}
                      error={!!errors.directhourshave}
                      helperText={errors.directhourshave?.message}
                    />}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="indirecthoursrequired"
                  {...register("indirecthoursrequired")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      multiline
                      placeholder="How many indirect hours are required for your license type?"
                      rows={2}
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black" },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('indirecthoursrequired', e.target.value);
                      }}
                    />}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="indirecthourshave"
                  {...register("indirecthourshave")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      multiline
                      placeholder="How many indirect hours do you already have?"
                      rows={2}
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black" },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('indirecthourshave', e.target.value);
                      }}
                      error={!!errors.indirecthourshave}
                      helperText={errors.indirecthourshave?.message}
                    />}
                />
              </Grid>
            </Grid>
          </>
        )}
        {showForm === 7 && (
          <>
            <Grid container spacing={1} mt={1} style={{ textAlign: 'left', textTransform: 'uppercase' }}>
              <Grid item xs={12}>
                <InputLabel>Liability Insurance Carrier</InputLabel>
                <Controller
                  name="liabilityInsuranceCarrier"
                  {...register("liabilityInsuranceCarrier")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Liability Insurance Carrier"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black" },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('liabilityInsuranceCarrier', e.target.value);
                      }}
                      error={!!errors.liabilityInsuranceCarrier}
                      helperText={errors.liabilityInsuranceCarrier?.message}
                    />}
                />
              </Grid>
              
              <Grid item xs={12}>
                <InputLabel>Policy Number</InputLabel>
                <Controller
                  name="policyNumber"
                  {...register("policyNumber")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Policy Number"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black" },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('policyNumber', e.target.value);
                      }}
                      error={!!errors.policyNumber}
                      helperText={errors.policyNumber?.message}
                    />}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Insurance Policy Start Date</InputLabel>
                <Controller
                  name="insurancePolicyStartDate"
                  {...register("insurancePolicyStartDate")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      type="date"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black" },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('insurancePolicyStartDate', e.target.value);
                      }}
                    />}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Insurance Policy End Date</InputLabel>
                <Controller
                  name="insurancePolicyEndDate"
                  {...register("insurancePolicyEndDate")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      type="date"
                      InputProps={{
                        style: { backgroundColor: "#aec3b0", color: "black" },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('insurancePolicyEndDate', e.target.value);
                      }}
                    />}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Upload Copy of Insurance</InputLabel>
                <input
                  type="file"
                  accept="image/*,application/pdf"
                  id="INSURANCE"
                  onChange={handleFileChangeStateInsurance}
                  style={{ display: "none" }}
                />
                <TextField
                  variant="outlined"
                  // type="file"
                  placeholder="Upload Insurance Certificate"
                  value={CopyInsuranceeURL}
                  InputProps={{
                    endAdornment: <FileDownloadOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => document.getElementById("INSURANCE").click()} />,
                    style: { backgroundColor: "#aec3b0", color: "black", textAlign: 'left', height: '40px' },
                  }}
                  fullWidth
                />

              </Grid>
            </Grid>
          </>
        )}
        {showForm === 8 && (
          <>
            <Box
              sx={{
                backgroundColor: "#aec3b0",
                borderRadius: "10px",
                padding: "20px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <Typography
                mb={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  textTransform: 'uppercase'
                }}
              >
                Complete User Agreement
              </Typography>
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  margin: "20px auto",
                  backgroundColor: "#fff",
                  border: '2px dashed grey',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                {Agreement ? (
                  <iframe
                    src={base64ImageAgreement}
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                ) : (
                  <label style={{ cursor: "pointer" }}>
                    Please Upload Signed User Agreement

                  </label>
                )}
              </div>
              <div
                style={{
                  margin: "20px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <input
                  type="file"
                  accept="application/pdf"
                  id="fileInput"
                  onChange={handleImageUploadAgreement}
                  style={{ display: "none" }}
                />
                <IconButton>
                  <FileDownloadOutlinedIcon
                    onClick={() => document.getElementById('fileInput').click()}
                    fontSize="large" color="black" cursor="pointer" fontWeight='bold' />
                </IconButton>
              </div>
              {AgreementError && (
                  <Typography style={{ color: 'red', fontSize: '12px',marginLeft:'5px'}}>
                   Please Select User Agreement
                  </Typography>
                )}
                <Grid container spacing={1}>
                <Grid item xs={12} >
                  <input
                    name='userAggreementCheck'
                    size='small'
                    type='checkbox'
                  />
                  <lable style={{ marginLeft: '3px', color: 'black', textTransform: 'uppercase' }}>I Agree To The User Agreement</lable>
                </Grid>
                {/* <Grid item xs={12}>
                <Typography style={{ color: 'balck', fontSize: '19px',marginLeft:'5px',textAlign:'left'}}>
                   Signature
                  </Typography>
                </Grid> */}
                <Grid item xs={12}>
                <Controller
                  name="sigantures"
                  {...register("sigantures")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      placeholder="Sign Here :"
                      multiline
                      rows={2}
                      InputProps={{
                        style: { backgroundColor: "#fff", color: "black" },
                      }}
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        setValue('sigantures', e.target.value);
                      }}
                    />
                  }
                /></Grid>
                </Grid>
            </Box>
          </>
        )}
      </Box>
      <Button style={nextButtonStyle} onClick={handleNextClick}>
         {showForm === 8 ?'SAVE' : 'NEXT' }
        <KeyboardDoubleArrowRightIcon
          style={{
            borderRadius: "50%",
            border: "2px solid white",
            marginLeft: "3px",
            color: "#b3946d",
            backgroundColor: "#fff",
            fontWeight: "bold",
          }}
        />
      </Button>
    </>
  );
};

export default SuperviseeSignUp;
