// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography,Modal } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import { ToastContainer, toast } from 'react-toastify';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SitePageRegistration from '../SitePageRegistration'

const SitePage = () => {
  document.title = "SitePage | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
 
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();
  const [sitepageid,setsitepageid]=useState('')
  const [sitepage, setsitepage] = React.useState(false);
  const handlesitepage= () => {
    setsitepage(true)
  };
  const handleclosesitepage = () => {
    setsitepageid('')
    // setLomnRequestid('')
    //  setPatientNamee('')
    setsitepage(false)
  };
  const columns = [
    {
      field: 'locationName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Site Name',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            // openUserRegistration(event,cellValues.row.id )
            setsitepageid(cellValues.row.id)
          }}
        >{`${cellValues.row.locationName}`}</Button>
      )
    },
    // {
    //   field: 'email',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Email Address',
    //   cellClassName: 'gridCell',
    //   flex: 0.5,
    //   renderCell: cellValues => (
    //     <Button
    //       size='small'
    //       variant='text'
    //       color='info'
    //       onClick={(event) => {
    //         openUserRegistration(event,cellValues.row.id )
    //       }}
    //     >{`${cellValues.row.email}`}</Button>
    //   )
    // },
    {
      field: 'siteType',
      headerClassName: 'super-app-theme--header',
      headerName: 'Site Type',
      flex: 0.3
    },
    {
      field: 'supervisorlastName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Site Supervisor',
      flex: 0.3,
      
    },
    {
      field: 'website',
      headerClassName: 'super-app-theme--header',
      headerName: 'Website',
      flex: 0.3
    },
    {
      field: 'startDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Start Date',
      flex: 0.3
    },
    {
      field: 'endDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'End Date',
      flex: 0.3
    },
    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                      id: cell.row.id,
                      inActive: true,
                      
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/Locations/AddLocations`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
   
  ]
  useEffect(() => {
    if(sitepageid>0)
    {
      handlesitepage()
    }
    },[sitepageid]);
  const [rows, setRows] = useState(null)
  const Userid = localStorage.getItem('userid')
  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Locations/getLocationByUserId?userId=${Userid}`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => {})

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
 
  const openUserRegistration = (event, id = 0) => {
    navigate('/SitePageRegistration', { state: { uid: id } })
  }
  const style = {
    position: 'absolute',
    top: '54%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.5)',
    padding: '15px',
    borderRadius: '8px',
    maxHeight: '89vh',
  };
  return (
    <>
     <Modal
          open={sitepage}
          onClose={handleclosesitepage}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} 
        >
          <Box sx={style}>
          <Headroom >
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            // textAlign: 'left',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                         Site Details

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                          onClick={handleclosesitepage}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>

        


            <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              padding: '5px',
              maxHeight: '57vh',
              overflowY: 'auto',
              marginTop: '4px',
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#3676c4 transparent', // thumb color and track color
              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#3676c4', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
            }} > 
            <SitePageRegistration uid={sitepageid} handleClose={handleclosesitepage}></SitePageRegistration>
           
         
             
              </Card>
              <ToastContainer
             position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
             
              />
            </Grid>


          </Box>
        </Modal>
      <div className="page-content">
            <Container >
              <div style={{ width: 200, marginTop: '5px', marginBottom: '0px' }}>
                <Button sx={{ mb: 1 }} className="Button" onClick={handlesitepage} variant='contained' style={{fontSize:'14px',fontWeight:'bold'}}>
                <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
                  Add New
                </Button>
              </div>
              <Box mb={0} style={{ marginTop: 0 }}>
              {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rows === null || rows.length === 0 ? ("No Record Found") : (
                    <DataGrid
                      rows={rows}
                      classes={gridClasses}
                      rowHeight={20}
                      columns={columns}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}
              </Box>
            </Container>
          </div>
          </>
      
     
  )
}

export default SitePage
