// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import { Grid, Typography, NativeSelect, Paper, InputLabel } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import CircularProgress from '@mui/material/CircularProgress'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import moment from 'moment'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Swal from "sweetalert2";
import UploadIcon from '@mui/icons-material/Upload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const Tickets = () => {
    document.title = "Tickets | Maevisory";
    const fields = [
        "id",
        "userId",
        "parentId",
        "subject",
        "createdDate",
        "createdBy",
        "updatedDate",
        "updatedBy",
        "inActive",
        "userName"
    ];
    const defValues = {
        id: 0,
        userId: '',
        parentId: '',
        subject: '',
        createdDate: '',
        createdBy: '',
        updatedDate: '',
        updatedBy: '',
        inActive: false,
        userName: ''
    };
    const {
        register,
        control,
        handleSubmit,
        getValues,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        defaultValues: defValues
    });

    const [isLoading, setLoading] = useState(false)
    const getRowSpacing = React.useCallback(params => {
        return {
            top: params.isFirstVisible ? 0 : 0.5,
            bottom: params.isLastVisible ? 0 : 0.8
        }
    }, [])
    const token = localStorage.getItem('Token')
    const auth = `Bearer ${token}`
    const headers = {
        Authorization: auth
    }
    const gridRowStyle = {
        boxShadow: 2,
        border: 2,
        borderRadius: 2,
        background: 'drak',
        fontSize: '13.4px !important',
        color: 'drak !important',
        borderColor: '#326C1D',
        '& .MuiDataGrid-cell:hover': {
            color: 'rgba(5, 152, 236, 0.637) !important',
            fontSize: '14.6px !important',
        },
        '& .super-app-theme--header': {
            backgroundColor: '#377562',
            borderRadius: 0.1,
            color: 'white',
            fontSize: '16px !important',

        },

    }
    const gridClasses = {
        columnHeader: 'MuiDataGrid-columnHeaders',
    };
    const [refresh, setRefresh] = useState(false)
    const [rows, setRows] = useState(null)
    const [OpenModal, setOpenModal] = React.useState(false);
    const [rowId, setRowID] = React.useState(0);
    const HandleOpen = () => {
        setOpenModal(true)
    };
    const [file, setFile] = useState(null);
    const [documentName, setDocumentName] = useState('');
    const [documentType, setDocumentType] = useState(''); 
    const [TrackId, setTrackId] = useState('');

    console.log(TrackId,'mmmmmmmmmmm')
    const HandleCloseModal = () => {
        setOpenModal(false)
        fields.forEach((field) => setValue(field, ''));
        reset();
        setRowID(0);
        setFile('')
        setDocumentType('')
        setTrackId('')
        setDocumentName('')
    };
    const columns = [
        {
            field: 'userName',
            headerClassName: 'super-app-theme--header',
            headerName: 'User Name',
            cellClassName: 'gridCell',
            flex: 0.3,
            renderCell: (cellValues) => (
                <Button
                    style={{ color: 'rgba(58, 53, 65, 0.87)', display: 'flex', justifyContent: 'flex-start' }}
                    size="small"
                    variant="text"
                    color="info"
                    onClick={() => {
                        setOpenModal(true);
                        setRowID(cellValues.row.id);
                        let rowid = cellValues.row.id;
                        axios
                            .get(`${process.env.REACT_APP_API_URL}/UserTicket/GetTicketById?id=${rowid}`, { headers })
                            .then((response) => {
                                fields.forEach(field => {
                                    setValue(field, response[field]);
                                })
                            })
                            .catch((error) => {
                                console.error('Error fetching data:', error);
                            });
                    }}
                >{`${cellValues.row.userName}`}</Button>
            )
        },
        {
            field: 'subject',
            headerClassName: 'super-app-theme--header',
            headerName: 'Subject',
            flex: 0.3
        },
        {
            field: 'isActive',
            headerClassName: 'super-app-theme--header',
            headerName: 'Delete',
            sortable: true,
            flex: 0.1,
            renderCell: cell => (
                <DeleteIcon
                    style={{
                        marginRight: '5px',
                        float: 'right',
                        marginTop: '5px',
                        color: 'red',
                        cursor: 'pointer'
                    }}
                    onClick={cellValues => {
                        confirmAlert({
                            message: 'Do you want to delete the record.',
                            closeOnEscape: true,
                            closeOnClickOutside: true,
                            overlayClassName: 'overlay-custom-class-name',
                            buttons: [
                                {
                                    label: 'Yes',
                                    onClick: () => {
                                        const Id = cell.row.id
                                        const postData = {
                                            // inActive: true,
                                            id: cell.row.id
                                        }
                                        axios
                                            .post(`${process.env.REACT_APP_API_URL}/UserTicket/DeleteUserTicket?ticketId=${Id}`, postData, {
                                                headers
                                            })
                                            .then(response => {
                                                setRefresh(!refresh)
                                                // setOpenNote(false)
                                                // console.log('response.data: ', response.data)
                                            })
                                            .catch(error => {
                                                console.error('There was an error!', error)
                                            })
                                    }
                                },
                                {
                                    label: 'No'
                                }
                            ]
                        })
                    }}
                ></DeleteIcon>
            )
        }

    ]
    useEffect(() => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_URL}/UserTicket/GetAllUserTicket`, {
                headers
            })

            .then(response => {
                setRows(response)
                setLoading(false)

            })
            .catch(error => { });
    }, [refresh]);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600, // Added width for more horizontal space
        height: 320,
        backgroundColor: 'white', // Use full name for consistency
        border: '2px solid #ddd', // Subtle light gray for the border
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Black shadow with soft transparency
        borderRadius: '12px', // Slightly more rounded corners for a modern look
        overflowY: 'auto',
        padding: '16px' // Added padding to make the content inside look less cramped
    };
    const [UserList, setUserList] = useState([]);
    const Userid = localStorage.getItem('userid')
    const UserType = localStorage.getItem("User_Type")

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?showAll=${true}`, {
                headers
            })
            .then(response => {
                var prac = [];
                response.forEach(field => {
                    prac.push({
                        label: field.userName,
                        value: field.id
                    });
                });
                setUserList(prac);
            })
            .catch(error => { });
    }, []);

    const onSubmit = async (data) => {
        try {
            // Prepare the data for the first API call
            let postData = {
                ...data,
                id: 0
            };
    
            // Call the first API
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/UserTicket/AddEditUserTicket`,
                postData,
                { headers }
            );
    
            const track = response.ticket;
            setTrackId(track.id);
            HandleCloseModal();
            setRefresh(true);
    
            // If a file exists, proceed with the second API call
            if (file && documentName) {
                await onSubmitDocument(track.id);  // Pass the track.id to the second API call
            }
    
            // Show success message for the first API call
            Swal.fire({
                title: 'Success!',
                text: 'Ticket saved successfully.',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            });
        } catch (error) {
            // Handle any errors that occur during the API calls
            console.log(`Error: ${error.message}`);
        }
    };
    
    // Modify onSubmitDocument to accept track.id and post the document data
    const onSubmitDocument = async (trackId) => {
        let postData = {
            ticketId: trackId,  // Use the trackId from the first API response
            fileName: documentName,
            base64: file,
            type: documentType
        };
    
        try {
            // Call the second API to upload the document
            await axios.post(
                `${process.env.REACT_APP_API_URL}/UserTicket/AddTicketDocument`,
                postData,
                { headers }
            );
        } catch (error) {
            console.log(`Error: ${error.message}`); // Show error if the document API call fails
        }
    };
    
 const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            // Read the file as Base64
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onloadend = () => {
                setFile(reader.result);
                setDocumentName(selectedFile.name); 
                const type = selectedFile.type.split('/')[1]; 
                setDocumentType(type); 
            };
        }
    };
    // const onSubmitDocument = () => {
    //     let postData = {
    //         ticketId:TrackId,
    //         fileName: documentName,
    //         base64: file,
    //         type:documentType
    //     };

    //     axios
    //         .post(`${process.env.REACT_APP_API_URL}/UserTicket/AddTicketDocument`, postData, { headers })
    //         .then(response => {
    //             // setRefresh(true);
    //             // Swal.fire({
    //             //     title: 'Success!',
    //             //     text: 'Ticket Add successfully.',
    //             //     icon: 'success',
    //             //     timer: 2000,
    //             //     showConfirmButton: false,
    //             // });
    //         })
    //         .catch(error => {
    //             console.log(`Error: ${error.message}`); // Show error if the API call fails
    //         });
    // };
    return (
        <div className="page-content">
            <Container >
                <Modal
                    open={OpenModal}
                    onClose={HandleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <>
                        <Box sx={style}>
                            <Headroom >
                                <Grid
                                    container
                                    style={{ backgroundColor: '#356F60', position: 'sticky', borderRadius: '4px' }}
                                >
                                    <Grid item xs={12} sm={12} xl={12}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'left',
                                                justifyContent: 'space-between',
                                                height: '2rem'
                                            }}
                                        >
                                            <DialogTitle
                                                style={{
                                                    flex: 1,
                                                }}
                                            >
                                                <h1
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginTop: '-10px',
                                                        color: '#fff',
                                                        textDecoration: 'underline',
                                                        fontSize: '1rem'

                                                    }}
                                                >
                                                    Add Tickets

                                                </h1>
                                            </DialogTitle>
                                            <CancelOutlinedIcon
                                                onClick={HandleCloseModal}
                                                fontSize="large"
                                                color="red"
                                                style={{
                                                    color: 'red',
                                                    paddingRight: '5px',
                                                    cursor: 'pointer',
                                                    float: 'right'
                                                }}
                                            ></CancelOutlinedIcon>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Headroom>
                            <Grid container spacing={1}>
                                <Grid item xs={12} >
                                    <InputLabel>User</InputLabel>
                                    <Controller
                                        name="userName"
                                        control={control}
                                        {...register('userName')}
                                        render={({ field }) => {
                                            return (
                                                <ReactSelect
                                                    fullWidth
                                                    value={UserList.find((option) => option.label == getValues('userName'))}
                                                    onChange={e => {
                                                        setValue("userId", e.value);
                                                        // setValue('userName', e.label);
                                                    }}
                                                    options={UserList}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <InputLabel>Subject</InputLabel>
                                    <Controller
                                        name='subject'
                                        {...register('subject')}
                                        control={control}
                                        render={({ field }) => (
                                            <TextareaAutosize
                                                aria-label='minimum height'
                                                minRows={5}
                                                placeholder='Enter Subject'
                                                style={{
                                                    width: '100%',
                                                    borderRadius: '8px',
                                                    border: '2px solid #black',
                                                    height: '70px', // set a fixed height here
                                                }}
                                                {...field}
                                                fullWidth

                                            />
                                        )}
                                    />

                                </Grid>
                                <Grid item xs={12}
                                    style={{
                                        marginTop: '10px',
                                        padding: '10px',
                                        backgroundColor: '#f9f9f9',
                                        border: '1px solid #ddd',
                                        borderRadius: '4px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                    }}
                                >
                                    <InsertDriveFileIcon style={{ color: '#3f51b5' }} />
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: '#333' }}>
                                        {documentName || "No Documents selected"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <input
                                        type="file"
                                        id="fileInput"
                                        onChange={handleFileChange}
                                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                                        style={{ display: 'none' }}
                                    />
                                    <Button
                                        onClick={() => document.getElementById('fileInput').click()}
                                        className="Button"
                                        fullWidth
                                        variant="contained"
                                        style={{
                                            fontSize: '14px',
                                            marginTop: '10px',
                                            backgroundColor: '#3f51b5',
                                            color: '#fff',
                                            cursor: 'pointer',

                                        }}
                                    >
                                        Upload Document
                                        <UploadIcon style={{ marginLeft: '5px' }} />
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={6} xl={6}>
                                    <Button
                                        className="Button"
                                        onClick={handleSubmit(onSubmit)}
                                        fullWidth
                                        variant='contained' style={{
                                            fontSize: '14px', fontWeight: 'bold',
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={6} xl={6}>
                                    <Button
                                        className="Button"
                                        onClick={HandleCloseModal}
                                        fullWidth
                                        variant='contained' style={{
                                            fontSize: '14px', fontWeight: 'bold',
                                        }}
                                    >
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>

                    </>
                </Modal>
                <Grid container spacing={1}>
                    <Button className="Button" onClick={HandleOpen} variant='contained' style={{ height: '35px', marginBottom: '5px' }}>
                        <AddOutlinedIcon
                            fontSize='small'
                            style={{
                                color: 'white',
                                paddingRight: '4px',
                                paddingBottom: '2px',

                            }}
                        ></AddOutlinedIcon>
                        Add New
                    </Button>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Box mb={0} style={{ marginTop: 0 }}>
                            {isLoading ? (
                                <div style={{ margin: '5rem', textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div mb={0} style={{ height: 400, width: '100%' }}>
                                    {rows === null || rows.length === 0 ? ("No Record Found") : (
                                        <DataGrid
                                            rows={rows}
                                            classes={gridClasses}
                                            rowHeight={20}
                                            columns={columns}
                                            getRowSpacing={getRowSpacing}
                                            //checkboxSelection
                                            fullWidth
                                            sx={gridRowStyle}
                                            pagination
                                            autoPageSize
                                        />
                                    )}
                                </div>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>


    )
}

export default Tickets
