// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography, Modal ,TextField,Paper} from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import { ToastContainer, toast } from 'react-toastify';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SupervisorRegistration from '../SupervisorRegistration'
import { Checkbox } from '@mui/material'; // Import Checkbox component
import moment from 'moment';
const Supervisor = () => {
  document.title = "Supervisor | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },
    '& .MuiCheckbox-root': {
      color: '#1976d2', // Default checkbox color
      marginLeft: '-10px',
      display: 'none',
      '&.Mui-checked': {
      },
      '&:hover': {
        backgroundColor: 'rgba(25, 118, 210, 0.1)', // Hover effect
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18, // Smaller size
        zIndex: 10,
        display: 'none'
      },
    },

  }

  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();
  const [Supervisorid, setSupervisorid] = useState('')
  const [Supervisor, setSupervisor] = React.useState(false);
  const handleSupervisor = () => {
    setSupervisor(true)
  };
  const handlecloseSupervisor = () => {
    setSupervisorid('')
    // setLomnRequestid('')
    //  setPatientNamee('')
    setSupervisor(false)
  };
  const [isUnpaidModalOpen, setIsUnpaidModalOpen] = useState(false); // Modal state
  const [selectedRow, setSelectedRow] = useState(null); // Selected row data
  const [SupId, setSupid] = useState('')
  // Function to handle Unpaid Payment button click
  const handleUnpaidPaymentClick = (row) => {
    setSelectedRow(row); // Set the clicked row's data
    setIsUnpaidModalOpen(true); // Open the modal
  };

  // Function to handle modal close
  const handleModalClose = () => {
    setIsUnpaidModalOpen(false); // Close the modal
    setSelectedRows([]); // Clear selected row data
  };
  const columns = [
    {
      field: 'userName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button
          style={{ display: 'flex', justifyContent: 'flex-start' }}
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            // openUserRegistration(event,cellValues.row.id )
            setSupervisorid(cellValues.row.id)
          }}
        >{`${cellValues.row.userName}`}</Button>
      )
    },

    {
      field: 'email',
      headerClassName: 'super-app-theme--header',
      headerName: 'Email Address',
      flex: 0.6
    },
    // {
    //   field: 'dob',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'DOB',
    //   flex: 0.3

    // },
    {
      field: 'userType',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Type',
      flex: 0.2
    },
    {
      field: 'dateJoined',
      headerClassName: 'super-app-theme--header',
      headerName: 'Sign Up Date',
      flex: 0.3,
      valueGetter: (params) => moment(params.row.dateJoined).format('MM-DD-YYYY') 
    },
    {
      field: 'subscriptionStatus',
      headerClassName: 'super-app-theme--header',
      headerName: 'Subscription Status',
      flex: 0.3
    },
    {
      field: 'totalUnpaidPayment',
      headerClassName: 'super-app-theme--header',
      headerName: 'UnPaid Payment',
      flex: 0.3,
      renderCell: (cellValues) => (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            handleUnpaidPaymentClick(cellValues.row); // Set selected row
            setSupid(cellValues.row.id); // Set supervisor ID
          }}// Pass row data to handler
        >{`${cellValues.row.totalUnpaidPayment}`}</Button>
      ),
    },
    {
      field: 'isActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {

                      isActive: true,
                      id: cell.row.id,
                      FullName:cell.row.userName,
                      userName:cell.row.userName
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/Users/userRegistration`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
  ]
  useEffect(() => {
    if (Supervisorid > 0) {
      handleSupervisor()
    }
  }, [Supervisorid]);

  const [rows, setRows] = useState(null)
  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Supervisor'}`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => { })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])

  const openUserRegistration = (event, id = 0) => {
    navigate('/SupervisorRegistration', { state: { uid: id } })
  }
  const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #EA7772',
    boxShadow: 24,
    // overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',


  }
  const [UnpaidRows, setUnpaidRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  };

  const handleRowSelection = (newSelectionModel) => {
    setSelectedRows(newSelectionModel);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Transaction/get-all-payments-to-Supervisors?supId=${SupId}`, {
        headers
      })
      .then((response) => {
        // Make sure to provide a unique id to each row
        const rowsWithId = response.map((row, index) => ({
          ...row,
          id: row.paymentId || `row-${index}`,
        }));
        setUnpaidRows(rowsWithId);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [SupId]);
  const ColmnsUnpaid = [
    {
      field: 'checkbox',
      headerName: 'Checkbox',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <input
          style={{ position: 'relative', fontSize: '30px' }}
          type="checkbox"
          checked={selectedRows.includes(params.row.id)}
          onChange={() => handleCheckboxChange(params.row.id)}
        />
      ),
      flex: 0.3,
      cellClassName: 'gridCell',
    },
    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'paymentId',
      cellClassName: 'gridCell',
      flex: 0.3,
    },
    // {
    //   field: '',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: '',
    //   cellClassName: 'gridCell',
    //   flex: 0.3,
    //   renderCell: (params) => (
    //     <input
    //       style={{
    //         marginLeft: '-14px', // Adjust checkbox position
    //         zIndex: 1000, 
    //         position: 'relative',
    //         fontSize: '30px',
    //         display:'none',
    //         cursor:'pointer'
    //       }} 
    //       type="checkbox"
    //       checked={selectedRows.includes(params.row.id)} // Check if the row is selected
    //       onChange={() => handleCheckboxChange(params.row.id)} // Handle change event
    //     />
    //   ),
    // },
    {
      field: 'amount',
      headerClassName: 'super-app-theme--header',
      headerName: 'Amount',
      cellClassName: 'gridCell',
      flex: 0.3,
    },
    {
      field: 'supervisorId',
      headerClassName: 'super-app-theme--header',
      headerName: 'Supervisor Id',
      flex: 0.6,
    },
  ];

  const handleSubmitPaid = () => {
    const selectedData = UnpaidRows.filter((row) => selectedRows.includes(row.id));
    const updatedData = selectedData.map((row) => ({
      ...row,
      isChecked: true,
    }));
    axios
      .post(`${process.env.REACT_APP_API_URL}/Transaction/process-transfer-to-supervisor`, updatedData, {
        headers
      })
      .then((response) => {
        // alert('Selected rows submitted successfully!');
        handleModalClose()

      })
      .catch((error) => {
        console.error(error);
      });
  };
  const SupModalStyle = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 500,
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius: '8px',
  }

  const [isSubscribe, setIsSubscribe] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const handleSubscribeChange  = () => {
    if(isSubscribe == false){
      setIsSubscribe(true);
    }else if (isSubscribe == true){
      setIsSubscribe(false)
    }
  };
  useEffect(() => {
      onPatientHandle();
  }, [isSubscribe ,showAll]);
  const handleShowAllChange = (event) => {
    onPatientHandle()
    if(showAll == false){
      setShowAll(true);
    }else if (showAll == true){
      setShowAll(false)
    }
  };

  const onPatientHandle = async (value) => {
    let postData = {
      isSubscribe: isSubscribe,
      showAll: showAll,
      userType: 'Supervisor',
      searchTerm: value,
    };
    axios.get(`${process.env.REACT_APP_API_URL}/Users/getUsers`, {
      params: postData,
      headers: headers
    })
      .then((response) => {
        setRows(response)
        console.log('Response:', response);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  };

  return (
    <React.Fragment>
      <Modal
        open={Supervisor}
        onClose={handlecloseSupervisor}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={true} title={'Title'}
      >
        <Box sx={style}>
          <Headroom >
            <Grid
              container
              style={{ backgroundColor: '#356F60', position: 'sticky' }}
            >
              <Grid item xs={12} sm={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'space-between',
                    height: '2rem'
                  }}
                >
                  <DialogTitle
                    style={{
                      flex: 1,
                      // textAlign: 'left',
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        // textAlign: 'left',
                        marginTop: '-10px',
                        color: '#fff',
                        textDecoration: 'underline',
                        fontSize: '1rem'

                      }}
                    >
                      Supervisor Registration

                    </h1>
                  </DialogTitle>
                  <CancelOutlinedIcon
                    onClick={handlecloseSupervisor}
                    fontSize="large"
                    color="red"
                    style={{
                      color: 'red',
                      paddingRight: '5px',
                      cursor: 'pointer',
                      float: 'right'
                      // marginLeft: 'auto',
                      // marginRight: '1.5rem'
                    }}
                  ></CancelOutlinedIcon>
                </div>
              </Grid>
            </Grid>
          </Headroom>




          <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              overflow: 'auto',
              /* Other styles */
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#356F60 transparent', // thumb color and track color
              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#356F60', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
              // zIndex: "10",


              height: '80vh'
            }} >
              <SupervisorRegistration uid={Supervisorid} handleClose={handlecloseSupervisor} setRefresh={setRefresh} refresh={refresh} ></SupervisorRegistration>



            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"

            />
          </Grid>


        </Box>
      </Modal>
      <Modal
        open={isUnpaidModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={SupModalStyle}>
          <Card bordered={false} style={{
            overflow: 'auto', padding: '20px',
            scrollbarWidth: 'thin',
            scrollbarColor: '#356F60 transparent',
            '&::-webkit-scrollbar': {
              width: 8,
              height: 8,
            },
            height: '80vh'
          }} >
            <Grid container spacing={1} style={{ background: '#356F60', padding: '3px', borderRadius: '8px' }}>
              <Grid item xs={11}>
                <Typography variant='h5' style={{ fontWeight: 'bold', color: "#fff" }}>Unpaid Payment Details</Typography>
              </Grid>
              <Grid item xs={1}>
                <CancelOutlinedIcon
                  onClick={handleModalClose}
                  fontSize="large"
                  color="red"
                  style={{
                    color: 'red',
                    paddingRight: '5px',
                    cursor: 'pointer',
                    float: 'right'
                  }}
                ></CancelOutlinedIcon>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={2}>
              <Grid item xs={12} style={{ height: 400, width: '100%' }}>
                {UnpaidRows.length === 0 ? (
                  'No Record Found'
                ) : (
                  <DataGrid
                    rows={UnpaidRows}
                    columns={ColmnsUnpaid}
                    getRowSpacing={getRowSpacing}
                    fullWidth
                    sx={gridRowStyle}
                    classes={gridClasses}
                    autoPageSize
                    checkboxSelection={true} // Disable built-in checkbox selection
                    disableSelectionOnClick // Prevent default selection behavior
                    onRowSelectionModelChange={handleRowSelection} // Sync row selection
                    rowSelectionModel={selectedRows} // Controlled selection model
                    pagination
                  />
                )}
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={2}>
                <Button variant="contained" className="Button" onClick={handleModalClose}
                  style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold', float: 'right', marginBottom: '10px' }}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" className="Button" onClick={handleSubmitPaid}
                  style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold', float: 'right', marginBottom: '10px' }}
                >
                  Payment Submit
                </Button>
              </Grid>
            </Grid>

          </Card>
        </Box>
      </Modal>
      <div className="page-content">
        <Container >
          <Grid container spacing={1}>
        
            <Grid item xs={12}>
              <Paper
                elevation={4}
                style={{
                  padding: '20px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '8px',
                  marginBottom: '16px',
                  width: '100%',
                  margin: '10px auto',
                  border: '2px solid #326C1D',
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={1.3}>
                    <Typography
                      style={{
                        color: '#333',
                        fontSize: '18px',
                        marginTop: '6px',
                        fontWeight: 'bold',
                        width: '90px',
                      }}
                    >
                      User:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      placeholder="Enter Supervisor"
                      onKeyUp={(ev) => {
                        onPatientHandle(ev.target.value);
                      }}
                    />


                  </Grid>
                  <Grid item xs={2} mt={1}>

                    <Checkbox
                      style={{ marginTop: '-12px' }}
                      // value={isSubscribe == true}
                      // onChange={(e) => setIsSubscribe(true)}
                      value={isSubscribe}
                      onChange={handleSubscribeChange}
                    />
                    <Typography style={{ marginTop: '-33px', marginLeft: '40px' }}>Subscribe </Typography>
                  </Grid>
                  <Grid item xs={2} mt={1}>
                    <Checkbox
                      style={{ marginTop: '-12px' }}
                      value={showAll}
                      onChange={handleShowAllChange}
                    />
                    <Typography style={{ marginTop: '-33px', marginLeft: '40px' }}>Show All </Typography>
                  </Grid>
                  <Grid item xs={2} >
                    <Button sx={{ mb: 1 }} className="Button" onClick={handleSupervisor} variant='contained' style={{ fontSize: '14px', fontWeight: 'bold' }}>
                      <AddOutlinedIcon
                        fontSize='small'
                        style={{
                          color: 'white',
                          paddingRight: '4px',
                          paddingBottom: '2px'
                        }}
                      ></AddOutlinedIcon>
                      Add New
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} xl={12}>
              <Box mb={0} style={{ marginTop: 0 }}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rows === null || rows.length === 0 ? ("No Record Found") : (
                      <DataGrid
                        rows={rows}
                        classes={gridClasses}
                        rowHeight={25}
                        columns={columns}
                        getRowSpacing={getRowSpacing}
                        //checkboxSelection
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>


  )
}

export default Supervisor
