import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";

// users
// import user1 from "../../../assets/images/users/avatar-1.jpg";
import { Typography } from "@mui/material";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const userName = localStorage.getItem("UserName")
  const UserType = localStorage.getItem('User_Type')

  const [username, setusername] = useState("Admin");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);
  useEffect(() => {
    const checkAndSimulateLogout = () => {
      const now = new Date();
      const lastLogin = localStorage.getItem("lastLoginTime");
      const lastLoginDate = lastLogin ? new Date(lastLogin) : null;
  
      // Calculate 2 AM for today
      const today2AM = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        2, // Hour (2 AM)
        0,
        0,
        0
      );
  
      // Check if it's after 2 AM today and the user hasn't logged out
      if (lastLoginDate && lastLoginDate < today2AM && now > today2AM) {
        const logoutLink = document.querySelector(".dropdown-item");
        if (logoutLink) {
          console.log("Logging out automatically because it's past 2 AM");
          logoutLink.click();
        }
      }
  
      // Update the last login time
      localStorage.setItem("lastLoginTime", now.toISOString());
    };
  
    checkAndSimulateLogout();
  
    // Set an interval to check daily at a regular interval
    const intervalId = setInterval(() => {
      checkAndSimulateLogout();
    }, 60 * 60 * 1000); // Check every hour
  
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);
  
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src="/Avatar.png"
            alt="Header Avatar"
          /> */}
          {/* <span className="d-none d-xl-inline-block ms-2 me-2"><b>{userName}</b></span> */}
          <span className="d-none d-xl-inline-block ms-2 me-2"><b style={{ fontSize: "19px" }}>Mavesory</b></span>
          <i  style={{ fontSize: "20px" }} className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
        <DropdownItem style={{ textAlign: "left" }}>
        <i className="ri-user-line align-middle me-2 text-info" />
            My Profile
          </DropdownItem> 
          <DropdownItem style={{ textAlign: "left" }}>
          <i className="ri-file-text-line align-middle me-2 text-success" />
            Subscrition
          </DropdownItem> 
          <DropdownItem style={{ textAlign: "left" }}>
          <i className="ri-file-list-line align-middle me-2 text-warning" />
            User Agreement
          </DropdownItem> 
          <DropdownItem style={{ textAlign: "left" }}>
          <i className="ri-question-line align-middle me-2 text-primary" />
            Submit a Help
          </DropdownItem> 
          <DropdownItem style={{ textAlign: "left" }}>
          <i className="ri-ticket-line align-middle me-2 text-secondary" />
          Tickets
          </DropdownItem> 
          <div className="dropdown-divider" />
          <Typography style={{color:'black',textAlign: "center",fontWeight:'bold',fontSize:'12px'}}>{UserType}</Typography>

          <Link to="/logout" className="dropdown-item">
            <Typography style={{ textAlign: "center",fontSize:'14px' }}>
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
              {props.t("Logout")}</Typography>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
